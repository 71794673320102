  import React, { useState, useEffect } from 'react';
  import CustomerInfoSection from '../../components/saibaLifeSection/CustomerInfoSection/CustomerInfoSection';
  import PolicyDetailsSection from '../../components/saibaLifeSection/PolicyDetailsSection/PolicyDetailsSection';
  import './PolicyForm.css';
  import PolicyParticularDetails from '../../components/saibaLifeSection/PolicyParticularDetails/PolicyParticularDetails';
  import PaymentModeSection from '../../components/saibaLifeSection/paymentModeSection/PaymentModeSection';
  import ServicingDetailsSection from '../../components/saibaLifeSection/ServicingDetails/servicingDetails';
  import BrokerageDetailsSection from '../../components/saibaLifeSection/BrokerDetailsSection/brokrageDetailsSection';
  import PartnerBrokerSection from '../../components/saibaLifeSection/PartnerBrokerSection/PartnerBrokerSection';
  import CoBrokerSection from '../../components/saibaLifeSection/CoBrokerSection/CoBrockerSection';
  import CampaignPolicyStatusSection from '../../components/saibaLifeSection/CampaignPolicyStatusSection/CampaignPolicyStatusSection';
  import axios from 'axios';
  import { useNavigate } from 'react-router-dom';
  import { v4 as uuid } from 'uuid';
  import { v4 as uuidv4 } from 'uuid';
// import CustomerNomineeSection from './../../components/saibaLifeSection/lifeNomineeSection/CustomerInfoSection';
import NomineeDetailsSection from '../../components/saibaLifeSection/lifeNomineeSection/NomineeDetails';
import { Section } from './../products/styles';

  const PolicyLifeForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      controlNo: '',
      enquiryCode: '',
      customerID: '',
      // bizType: 'new',
      customerName: '',
      email: '',
      phone: '',
      policyHolderName: '',
      insuredName: '',
      // nominee details
      nomineeName: '',
      nomineeRelation: '',
      nomineeAge: '',
      nomineeShare: '',

      ownerDetails: {
        fullName: '',
        email: '',
        mobileNo: '',
        address: '',
        registrationAddressSame: true,
        registrationAddress: '',
        maritalStatus: '',
        gender: '',
        dob: '',
        state: '',
        city: '',
        pincode: '',
        aadharNumber: '',
        isVehicleFinanced: 'No',
        financier: '',
        panNumber: '',
        tokenIdKotak: '',
        aadharImage: '',
        district: '',
        street: '',
        streetNumber: '',
      },

      // policy details
      // policy Section
      insurerName: '',
      insurerCity: '',
      insurerBranch: '',
      productDept: '',
      productName: '',
      proposalNo: '',
      submissionDate: '',
      receivedDate: '',
      appBusDate: '',
      commenceDate: '',
      paySchedule: '',
      term: '',
      ppt: '',
      maturityDate: '',
      lastPaymentDate: '',
      policyNo: '',
      moneyBackTime: '',
      moneyBackAmount: '',
      refNo: '',
      attachment: '',

      // brokerage details
      brokerageType: '',
      brokerageRemarks: '',
      firstYear: 0,
      brokerageAmount: 0,
      secondYear: 0,
      finalYear: 0,
      gstSTax: 0,

      // part IV
      // Payment details
      paymentMode: '',
      amount: 0,
      noOfInstallments: 0,
      bank: '',
      chequeNumber: '',
      dated: '',
      bankBranch: '',
      payModeRemark: '',
      micrCode: '',
      optForEcs: '',
      premiumRemittingDate: '',
      chequeBy: '',
      premiumReceiptDate: '',
      premiumReceiptNo: '',

      // part V
      posMispRef: 'Ref',
      posMispRefCode: '',
      posMispRefName: '',
      posId: '',
      cscPeCode: '',
      cscPeName: '',
      rmCe1Code: '',
      rmCe1Name: '',
      solicitorCe2Code: '',
      solicitorCe2Name: '',
      tcCode: '',
      tcName: '',

      // part VI
      sNo: 1,
      extensionType: 'Rider',
      extensionName: '',
      irdaLob: 'Health + Sickness',
      sumInsured: 0,
      premium: 0,
      taxable: 'Yes',
      riderRate: '',
      gstRate: '',
      gstAmount: 0,
      remarks: '',
      insuredAmount: 0,
      basicPremium: '',
      premiumAmount: '',
      totalRiderAmount: '',
      policyFees: '',
      avgGst: 0,
      gstSTaxAmount: 0, // GST/S.Tax Amount
      grossPremium: 0, // Gross Premium
      policyRemarks: '', // Policy Remarks

      // part VII
      partnerBrokerName: '',
      partnerBrokerCode: '',
      network: '',
      crossReferral: '',

      // part VIII
      coBrokerName: '',
      coBrokerBranchCode: '',
      coBrokerShare: '',

      // part IX
      campaignName: '',
      status: 'Logged in',
      plcvCode: '',
      plcvRemarks: '',

      // Motor Policy Details
    });

    const [addedParticularDetails, setAddedParticularDetails] = useState([]);
    const [addedNomineeDetails, setAddedNomineeDetails] = useState([]);
    const [addedParticularDetailsFiltered, setAddedParticularDetailsFiltered] =
      useState(null);
    const [addedNomineeDetailsFiltered, setAddedNomineeDetailsFiltered] =
      useState(null);

    const [coBrokers, setCoBrokers] = useState([]);
    const [isCoBrokerChecked, setIsCoBrokerChecked] = useState(false);
    const [isCheckedPartnerBroker, setIsCheckedPartnerBroker] = useState(false);

    const handleAddParticularDetail = (newData, finalPolicyData) => {
      // console.log("🚀 ~ handleAddParticularDetail ~ finalPolicyData:", finalPolicyData)
      setAddedParticularDetails([...addedParticularDetails, newData]);
      setAddedParticularDetailsFiltered(finalPolicyData);
    };

    const handleAddNomineeDetail = (newData, finalNomineeData) => {
      setAddedNomineeDetails([...addedNomineeDetails, newData]);
      setAddedNomineeDetailsFiltered(finalNomineeData);
    };

    const handleDeleteParticularDetail = (indexToDelete) => {
      const filteredData = addedParticularDetails.filter(
        (_, index) => index !== indexToDelete
      );
      setAddedParticularDetails(filteredData);
    };

    const handleDeleteNomineeDetail = (indexToDelete) => {
      const filteredData = addedNomineeDetails.filter(
        (_, index) => index !== indexToDelete
      );
      setAddedNomineeDetails(filteredData);
    };

    // Helper function to generate a 4-digit unique number
    function generateUniqueFourDigitNumber() {
      const uuidData = uuidv4();
      const hash = parseInt(uuidData.replace(/-/g, '').slice(0, 8), 16);
      return (hash % 9000) + 1000;
    }

    // Initialize enquiryCode on first load
    useEffect(() => {
      const uniqueNumber = generateUniqueFourDigitNumber();

      const savedControlNo = localStorage.getItem('controlNo');
      const newControlNo = savedControlNo || uniqueNumber;

      const savedEnquiryCode = localStorage.getItem('enquiryCode');
      const newCode = savedEnquiryCode || uuid();
      setFormData((prevState) => ({
        ...prevState,
        enquiryCode: newCode,
        controlNo: newControlNo,
      }));
      if (!savedEnquiryCode) {
        localStorage.setItem('enquiryCode', newCode);
        localStorage.setItem('controlNo', newControlNo);
      }
    }, []);

    const handleChange = (e) => {
      const { name, value } = e.target || e;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      console.log('Submitting form datatatat...', formData);

      // Create a fullFormData object matching the schema
      const fullFormData = {
        controlNo: formData.controlNo,
        enquiryId: formData.enquiryCode,
        userId: formData.posId,
        type: formData.bizType,
        customerID: formData.customerID,
        status: 'quotationGenerated',
        source: 'simpliDashboard',
        custCode: formData.custCode,
        customerName: formData.customerName,
        email: formData.email,
        phone: formData.phone,
        insuredName: formData.insuredName,
        insuredDOB: formData.insuredDOB,
        city: formData.insurerCity,

        price: formData.amount,
        gst: addedParticularDetailsFiltered.gstSTaxAmount,
        total: addedParticularDetailsFiltered.grossPremium,

        // policy type
        newPolicyData: {
          policyType: formData.policyType,
        },

        nomineeDetails: {
          fullName: formData.nomineeName,
          dob: formData.nomineeAge,
          relation: formData.nomineeRelation,
          share: formData.nomineeShare,
        },
        ownerDetails: {
          fullName: formData.customerName,
          email: formData.email,
          mobileNo: formData.phone,
          address: formData.ownerDetails.address,
          registrationAddressSame:
            formData.ownerDetails.registrationAddressSame,
          registrationAddress: formData.ownerDetails.registrationAddress,
          maritalStatus: formData.ownerDetails.maritalStatus,
        },
        policyHolderName: formData.customerName,
        insuranceType: formData.department,

        policyDetails: {
          insurerName: formData.insurerName,
          insurerCity: formData.insurerCity,
          insurerBranch: formData.insurerBranch,
          productDept: formData.productDept,
          productName: formData.productName,
          proposalNo: formData.proposalNo,
          submissionDate: formData.submissionDate,
          receivedDate: formData.receivedDate,
          appBusDate: formData.appBusDate,
          commenceDate: formData.commenceDate,
          paySchedule: formData.paySchedule,
          term: formData.term,
          ppt: formData.ppt,
          maturityDate: formData.maturityDate,
          lastPaymentDate: formData.lastPaymentDate,
          policyNo: formData.policyNo,
          moneyBackTime: formData.moneyBackTime,
          moneyBackAmount: formData.moneyBackAmount,
          refNo: formData.refNo,
          attachment: formData.attachment,
        },


        raw: {
          brokerageDetails: {
            brokerageType: formData.brokerageType,
            brokerageRemarks: formData.brokerageRemarks,
            firstYear: formData.firstYear,
            brokerageAmount: formData.brokerageAmount,
            secondYear: formData.secondYear,
            finalYear: formData.finalYear,
            gstSTax: formData.gstSTax,
          },
          paymentDetails: {
            paymentMode: formData.paymentMode,
            amount: formData.amount,
            noOfInstallments: formData.noOfInstallments,
            bank: formData.bank,
            chequeNumber: formData.chequeNumber,
            dated: formData.dated,
            bankBranch: formData.bankBranch,
            payModeRemark: formData.payModeRemark,
            micrCode: formData.micrCode,
            optForEcs: formData.optForEcs,
            premiumRemittingDate: formData.premiumRemittingDate,
            chequeBy: formData.chequeBy,
            premiumReceiptDate: formData.premiumReceiptDate,
            premiumReceiptNo: formData.premiumReceiptNo,
          },
          addedNomineeDetailsFiltered: addedNomineeDetailsFiltered,
          addedNomineeDetails: addedNomineeDetails.map((detail) => ({
            sNo: detail.sNo,
            nomineeName: detail.nomineeName,
            nomineeRelation: detail.nomineeRelation,
            nomineeAge: detail.nomineeAge,
            nomineeShare: detail.nomineeShare,
          })),
          //  from  motor
          refNo: formData.refNo,
          vehUser: formData.vehUser,
          address: formData.address,
          gvw: formData.gvw,
          noOfPassenger: formData.noOfPassenger,
          posDetails: {
            posRef: formData.posMispRef,
            posCode: formData.posMispRefCode,
            posName: formData.posMispRefName,
            posId: formData.posId,
          },
          addedParticularDetailsFiltered: addedParticularDetailsFiltered,
          addedParticularDetails: addedParticularDetails.map((detail) => ({
            sNo: detail.sNo,
            extensionType: detail.extensionType,
            extensionName: detail.extensionName,
            irdaLob: detail.irdaLob,
            sumInsured: detail.sumInsured,
            premium: detail.premium,
            taxable: detail.taxable,
            riderRate: detail.riderRate,
            gstRate: detail.gstRate,
            gstAmount: detail.gstAmount,
            remarks: detail.remarks,
            insuredAmount: detail.insuredAmount,
            basicPremium: detail.basicPremium,
            premiumAmount: detail.premiumAmount,
            totalRiderAmount: detail.totalRiderAmount,
            policyFees: detail.policyFees,
            avgGst: detail.avgGst,
            gstSTaxAmount: detail.gstSTaxAmount,
            grossPremium: detail.grossPremium,
          })),

          quickQuote: {
            price: formData.amount,
            policyStartDate: formData?.startDate,
            policyEndDate: formData?.endDate,
          },

          partnerBrokerDetails: {
            partnerBrokerName: formData?.partnerBrokerName,
            partnerBrokerCode: formData?.partnerBrokerCode,
            network: formData?.network,
            crossReferral: formData?.crossReferral,
          },

          coBrokers: coBrokers?.map((broker) => ({
            coBrokerName: broker?.coBrokerName,
            coBrokerBranchCode: broker.coBrokerName === 'self' ? 'NA' : 'NA',
            coBrokerShare: broker?.coBrokerShare,
          })),

          campaigningDetails: {
            campaignName: formData.campaignName,
            policyStatus: formData.policyStatus,
            plcvCode: formData.plcvCode,
            plcvRemarks: formData.plcvRemarks,
          },
        },
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/new-policy/life`,
          fullFormData
        );

        const enquiryIdData = response.data.data.lifeForm.enquiryId;
        if (enquiryIdData) {
          console.log('Form successfully submitted');

          // Remove items from localStorage
          try {
          if (!response.data.data.status === 'Success') {
            alert('Error submitting form:', response.data.data.message);
            console.log('Error submitting form:', response.data.data.message);
            return;
          }

            localStorage.removeItem('enquiryCode');
            localStorage.removeItem('controlNo');
            console.log('LocalStorage cleared');
          } catch (removeError) {
            console.error('Error clearing localStorage:', removeError);
          }

          // Navigate to the next page
          navigate(`/policyupload/${enquiryIdData}`);
        }

      } catch (error) {
        console.error('Error submitting form', error);
        // Show an error message to the user
      }
    };

    // for Partner Broker Section
    const handleCheckboxChangePartnerBroker = () => {
      setIsCheckedPartnerBroker(!isCheckedPartnerBroker);
    };

    // for co Workers
    const handleCheckboxChange = () => {
      setIsCoBrokerChecked(!isCoBrokerChecked);
    };

    const handleAddCoBroker = () => {
      setCoBrokers([
        ...coBrokers,
        {
          coBrokerName: formData.coBrokerName,
          coBrokerBranchCode: formData.coBrokerBranchCode,
          coBrokerShare: formData.coBrokerShare,
          sno: coBrokers.length + 1,
        },
      ]);

      // Reset the Co-Broker fields in the form
      setFormData({
        ...formData,
        coBrokerName: '',
        coBrokerBranchCode: '',
        coBrokerShare: '',
      });
    };

    const handleRemoveCoBroker = (indexToDelete) => {
      setCoBrokers(coBrokers.filter((_, index) => index !== indexToDelete));
    };

    const handleExit = () => {
      setFormData({}); // Clears the form
      console.log('Exiting form...');
    };

    return (
      <div>
        <form onSubmit={handleSubmit} className="policy-form">
          <p className="brokerPolicyHeading">Life Standard Policy Entry </p>
          <CustomerInfoSection
            formData={formData}
            handleChange={handleChange}
          />
          <NomineeDetailsSection 
          formData={formData} 
          handleAddNominee={handleAddNomineeDetail}
          addedNominees={addedNomineeDetails}
          handleDeleteNominee={handleDeleteNomineeDetail}
           />
          <PolicyDetailsSection
            formData={formData}
            handleChange={handleChange}
          />

          {/* Policy Particular Details Section */}
          <PolicyParticularDetails
            formData={formData}
            handleAddData={handleAddParticularDetail}
            addedData={addedParticularDetails}
            handleDelete={handleDeleteParticularDetail}
          />

          <PaymentModeSection formData={formData} handleChange={handleChange} />
          <ServicingDetailsSection
            formData={formData}
            handleChange={handleChange}
          />
          <BrokerageDetailsSection
            formData={formData}
            handleChange={handleChange}
          />
          <PartnerBrokerSection
            formData={formData}
            handleChange={handleChange}
            handleCheckboxChange={handleCheckboxChangePartnerBroker}
            isChecked={isCheckedPartnerBroker}
          />
          <CoBrokerSection
            formData={formData}
            handleChange={handleChange}
            handleAddCoBroker={handleAddCoBroker}
            handleRemoveCoBroker={handleRemoveCoBroker}
            coBrokers={coBrokers}
            handleCheckboxChange={handleCheckboxChange}
            isChecked={isCoBrokerChecked}
          />
          <CampaignPolicyStatusSection
            formData={formData}
            handleChange={handleChange}
          />

          <div className="saveExitBtnGrp">
            <button type="submit" className="submit-btn-policy-broker">
              Save
            </button>
            <button
              type="button"
              onClick={handleExit}
              className="exit-btn-policy-broker"
            >
              Exit
            </button>
          </div>
        </form>
      </div>
    );
  };

  export default PolicyLifeForm;
