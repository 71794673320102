export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

const fieldArray = {
    "motor-kotak": [
        "ownerFullName", "phoneNumber", "userFullName", "email", "address", "isSame", "diffrentAddress", "gender", "martialStatus",
        "dob", "state", "city", "pinCode", "isFinanced", "aadharNumber", "panNumber"
    ],
    "motor-godigit": [
        "ownerFullName", "phoneNumber", "userFullName", "email", "gender", "martialStatus",
        "dob", "state", "city", "pinCode", "aadharNumber", "panNumber", "uploadAadhar", "district", "flatNumber", "street", "streetNumber", 
    ],
    "motor-icici": [
        "ownerFullName", "phoneNumber", "userFullName", "email", "address", "isSame", "diffrentAddress", "gender", "martialStatus",
        "dob", "state", "city", "pinCode", "aadharNumber", "panNumber"
    ],
    "motor-hdfc": [
        "ownerFullName", "phoneNumber", "userFullName", "email", "address", "isSame", "diffrentAddress", "gender", "martialStatus",
        "dob", "state", "city", "pinCode", "isFinanced", "panNumber"
    ],
    "motor-reliance": [
        "ownerFullName", "phoneNumber", "userFullName", "email", "address", "isSame", "diffrentAddress", "gender", "martialStatus",
        "dob", "state", "city", "pinCode", "isFinanced", "panNumber"
    ],
    "motor-future": [
        "ownerFullName", "phoneNumber", "userFullName", "email", "address", "isSame", "diffrentAddress", "gender", "martialStatus",
        "dob", "state", "city", "pinCode", "aadharNumber", "panNumber"
    ],
    "common": [
        "ownerFullName", "phoneNumber", "userFullName", "email", "address", "isSame", "diffrentAddress", "gender", "martialStatus",
        "dob", "state", "city", "pinCode", "isFinanced", "aadharNumber", "panNumber", "uploadAadhar"
    ]
}

export default fieldArray