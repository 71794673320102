import React, { useRef, useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import { MainContainer } from "../../../../assets/common-styles/common-styles"
import { IoShareSocial } from "react-icons/io5";
import { CardContainer, Separator, ContinueBtn } from "./styles"
import Footer from "../../../../components/Footer/FooterNew"
import PrevArrowImg from '../../../../assets/commons/prev-arrow.svg'
import BrandImg from '../../../../assets/car-insurance/form-5/national.png'
import VideoImg from '../../../../assets/car-insurance/payments/video.svg';
import { motorEnquiryPayment, motorEnquiryPaymentVerification, updateCombinedModel, calculateCheckSum, motorEnquiryAPI, updateSaveProposalKotak, updateSaveProposalIcici, updateSaveProposalHdfc, updateSaveProposalReliance, updateSaveProposalDigit } from '../../../../Redux/CarsRedux';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosPOSTCall, axiosGETCall, axiosPOSTCall1, axiosPOSTCall2, checkSum } from "../../../../utilities/utils/apiPost"
import {apiPostCall1} from '../../../../utilities/site-apis';
import './preview.css'
const moment = require('moment');
const { copyToClipboard } = require('../../../../utilities/copy-url');

export const PreviewAfterPayment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [checkSumA, setCheckSumA] = useState();
    const [loading, setLoading] = useState(false);
    const { payu } = useSelector((state) => state.carsRedux.paymentDetails);
    const combinedReduxInsure = useSelector((state) => state.carsRedux.combinedRedux);
    console.log("combinedReduxInsure is ", combinedReduxInsure);
    console.log("enquiryIds  is1  ",);
    useEffect(() => {
        const queryParameter = new URLSearchParams(window.location.search)
        var enquiryIds = queryParameter.get("enquiryId");
        var obj = { type: "get", enquiryId: enquiryIds };
        if(enquiryIds){
        dispatch(motorEnquiryAPI(obj))
            localStorage.setItem('enquiryId', JSON.stringify({ enquiryId: enquiryIds}));
        }
    }, [])
    // display hdfc payment gateway
    const hdfcPayment = (checkSum, surl, furl, trnsno, appid, amount, subid) => {
        var formData = new FormData();

        // Append values to the FormData object
        formData.append('Appid', appid);
        formData.append('Trnsno', trnsno);
        formData.append('Amt', amount);
        formData.append('Subid', subid);
        formData.append('surl', surl);
        formData.append('furl', furl);
        formData.append('src', 'POST');
        formData.append('Chksum', checkSum);
        formData.append('Additionalinfo1', '');

        // Create a new form element
        var form = document.createElement('form');
        form.action = process.env.REACT_APP_HDFC_PAYMENT_URL;
        form.name = 'PAYMENTFORM';
        form.method = 'post';
        form._lpchecked = '1';

        // Iterate through FormData entries and create hidden input elements
        formData.forEach(function (value, key) {
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            form.appendChild(input);
        });

        // Append the form to the document body
        document.body.appendChild(form);

        // Submit the form
        form.submit();

    }

    // handle reliance payment
    // const displayReliancePayment = (proposal, amount, ckyc, pan) => {
    //     console.log("payment details", proposal, amount, ckyc, pan);
    //     const returnUrl = `${process.env.REACT_APP_REDIRECTION_BASE_URL}/car-insurance/car-payment-success`
    //     const url = `https://api.brobotinsurance.com/common/PaymentIntegration/PaymentIntegration?ProposalNo=${proposal}&userID=100002&ProposalAmount=${amount}&PaymentType=1&Responseurl=${returnUrl}&CKYC=${ckyc}&IsDocumentUpload=false&PanNo=${pan}&IsForm60=false`;
    //     console.log("payment URL", returnUrl,url);
    //     return
    //     window.location.href = url;
    // }


    const handlePaymentResponse = (response) => {
        var obj = {
            "paymentOrderId": response.txnid,
            "paymentId": response.mihpayid,
            "enquiryId": combinedReduxInsure.enquiryId,
            "slug": combinedReduxInsure.company
        };
        console.log("response...",response);
        axiosPOSTCall2('/motor/enquiry/payment/verify', { ...obj, ...response }, (callBack => {
            if (callBack.status == "Success") {
              if(callBack.data.Status = "0")
                navigate('/car-insurance/car-payment-success')           
            }
        }));
    }

    const displayPayu = (pd) => {
        let obj = { ...pd }
        window.bolt.launch(obj, {
            responseHandler: (response) => {
                console.log("initied", response)
                // Handle payment response
                handlePaymentResponse(response?.response);
            },
            catchException: (response) => {
                // Handle exception
                console.error('Exception:', response);
            },
        });
    };

    const createBreakIn = () =>{
        const slug = combinedReduxInsure?.company
        axiosPOSTCall(`/vehicleInsurance/motor/createBreakIn/${slug}`, { enquiryId: combinedReduxInsure.enquiryId }, (callBack => {
            if (callBack.status == "Success") {
                navigate('/car-insurance/payment/instructions')
            }
            else{
                alert(callBack?.message);
            }
        }))
    }

    const handlePayment = () => {
        const slug = combinedReduxInsure.company
        console.log("slug,slug", slug)
        // return
        if (slug == "motor-hdfc") {
            checkSum('/vehicleInsurance/motor/checksum', { enquiryId: combinedReduxInsure.enquiryId }, callBack => {
                console.log("callback", callBack)
                if (callBack.status == "Success") {

                    setCheckSumA(callBack.data.string._)
                    console.log("checkhjj", checkSumA, callBack.data.string._);
                    //   setSum(true)
                    hdfcPayment(callBack.data.string._, callBack.data.surl, callBack.data.furl, callBack.data.trnsno, callBack.data.appid, callBack.data.amount, callBack.data.subid)

                }
            })
        }
        else if (slug == "motor-kotak") {
            dispatch(motorEnquiryPayment({
                "enquiryId": combinedReduxInsure.enquiryId,
                "company": combinedReduxInsure.company,
                'price': combinedReduxInsure.price
            }))
        }
        else if (slug == "motor-icici") {
            dispatch(motorEnquiryPayment({
                "enquiryId": combinedReduxInsure.enquiryId,
                "company": combinedReduxInsure.company,
                'price': combinedReduxInsure.price
            })).then((res) => {
                window.location.href = res.payload.data.paymentLink
            })
        }
        else if (slug == "motor-godigit") {
            axiosPOSTCall("/vehicleInsurance/motor/payment/motor-godigit", { enquiryId: combinedReduxInsure.enquiryId }, (callBack => {
                if (callBack.status == "Success") {
                    console.log("callback..", callBack);
                    window.location.href = callBack.data;
                }
            }))
        }
        else if (slug == "motor-reliance") {
            const checkout={
                "enquiryId": combinedReduxInsure.enquiryId,
                "company": combinedReduxInsure.company,
                'price': combinedReduxInsure.price,
                proposal:combinedReduxInsure.saveProposal.MotorPolicy.ProposalNo,
                pan:combinedReduxInsure.kycReqRes1?.kyc_data.CKYC.result.PERSONAL_DETAILS.PAN,
                ckyc:combinedReduxInsure.kycReqRes1?.kyc_data.CKYC.result.PERSONAL_DETAILS.CKYC_NO
            };
            dispatch(motorEnquiryPayment(checkout)).then((res) => {
                if(res.payload.status == "Success"){
                    console.log("payment", res.payload);
                    document.open(); // Clears the current document
                    document.write(res.payload.data.data); // Writes the new HTML content
                    document.close();
                }
            })
            // displayReliancePayment(combinedReduxInsure.saveProposal.MotorPolicy.ProposalNo, combinedReduxInsure.saveProposal.MotorPolicy.FinalPremium, combinedReduxInsure.kycReqRes1.kyc_data.CKYC.result.PERSONAL_DETAILS.CKYC_NO, combinedReduxInsure.kycReqRes1.kyc_data.CKYC.result.PERSONAL_DETAILS.PAN,)
        }
        else if (slug == "motor-future") {
          const checkout={
            "enquiryId": combinedReduxInsure.enquiryId,
            "company": combinedReduxInsure.company,
            'price': combinedReduxInsure.price,
        };
          dispatch(motorEnquiryPayment(checkout)).then((res) => {
            if(res.payload.status == "Success"){
              const callBack = res.payload.data
              const form = document.createElement('form');
              form.id = 'paymentForm';
              form.action = res.payload.data.action;
              form.method = 'POST';
              Object.entries(res.payload.data.paymentParams).forEach(([name, value]) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = name;
                input.value = value;
                form.appendChild(input);
              });
              const checksumInput = document.createElement('input');
              checksumInput.type = 'hidden';
              checksumInput.name = 'CheckSum';
              checksumInput.value = res.payload.data.checksum;
              form.appendChild(checksumInput);
              document.body.appendChild(form);
              form.submit();
            }
        })
      }
    }

    useEffect(() => {
        if (payu && payu.txnid) {
            setLoading(false);
            displayPayu(payu)
        }
        else {
            setLoading(false);
        }
    }, [payu])




    return (
      <>
        <MainContainer>
          <CardContainer>
            <div className="backAndCopyPaymentLink">
              {/* <div className='back_btn_1'>
                            <img
                                className="arrow"
                                alt='back_btn_payment_4'
                                src={PrevArrowImg}
                                onClick={()=>navigate(`/car-insurance/main?enquiryId=${combinedReduxInsure.enquiryId}`)}
                            />
                            <p className="back-to-quotes">Back to Quotes</p>
                        </div> */}
              {!(combinedReduxInsure?.inspection == 'required') && (
                <div className="copy_link_btn">
                  <p
                    className="copy-link-btn-para"
                    onClick={copyToClipboard}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <IoShareSocial style={{ marginRight: '5px' }} />
                    <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Copy Payment Link
                    </span>
                  </p>
                </div>
              )}
            </div>

            <div className="flex apart cards-container">
              <div className="flex-1 mr-half">
                <div className="cards mb-1">
                  <h2>Almost there! You have completed all details</h2>
                  <p>Now, just review the information before payment</p>
                </div>
                <div className="cards mb-1">
                  <div className="flex column">
                    <p style={{ marginBottom: '1rem' }} className="brand-name">
                      Plan for {combinedReduxInsure?.ownerDetails?.fullName}
                    </p>
                    <div>
                      <div className="preview_plan_data">
                        <img
                          src={combinedReduxInsure?.quickQuote?.sluginfo?.logo}
                          alt="brand-img"
                          className="brand-img"
                        />
                        <div className="preview_plan_data_para">
                          <p className="brand-name">
                            {combinedReduxInsure?.quickQuote?.sluginfo?.name}
                          </p>
                        </div>
                      </div>
                      <Separator />
                      <div className="flex apart">
                      <p>Reg. Number</p>
                        <p>IDV</p>
                        <p>Previous Policy Number</p>
                      </div>
                      <div className="flex apart bold">
                      <p>{combinedReduxInsure?.number}</p>
                        <p>{combinedReduxInsure?.quickQuote?.idvCoverValue}</p>
                        <p>{combinedReduxInsure?.insurance?.insurancePolicyNumber}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cards mb-1">
                  <div className="flex column">
                    <h2>Your Proposal summary</h2>
                    <p>
                      We have shared these details on your email{' '}
                      {combinedReduxInsure?.ownerDetails?.email}
                    </p>

                    <Separator />
                    <div className="flex apart prop">
                      <p>Proposer</p>
                      <p>Proposal Number</p>
                      <p>Mobile Number</p>
                      <p>Email</p>
                    </div>
                    <div className="flex apart bold prop">
                      <p>{combinedReduxInsure?.ownerDetails?.fullName}</p>
                      <p>{combinedReduxInsure?.proposalNumber}</p>
                      <p>{combinedReduxInsure?.ownerDetails?.mobileNo}</p>
                      <p>{combinedReduxInsure?.ownerDetails?.email}</p>
                    </div>
                  </div>
                </div>

                <div className="cards mb-1">
                  <div className="flex column">
                    <h2>KYC Details</h2>

                    <Separator />
                    <div className="flex apart prop">
                      <p>Customer Name</p>
                      <p>CKYC Number</p>
                    </div>
                    <div className="flex apart bold prop">
                      <p>{combinedReduxInsure?.kycReqRes1?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.FULLNAME}</p>
                      <p>{combinedReduxInsure?.kycReqRes1?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.CKYC_NO}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-1 ml-half mt-1">
                <div className="cards mb-1">
                  <p className="brand-name">Payment Summary</p>
                  <Separator />
                  <div className="flex apart">
                    <p>Own Damage Premium</p>
                    <p>₹ {combinedReduxInsure?.quickQuote?.odPrice}</p>
                  </div>
                  <div className="flex apart">
                    <p>TP Premium</p>
                    <p>₹ {combinedReduxInsure?.quickQuote?.tpPrice}</p>
                  </div>
                  <div className="flex apart">
                    <p>GST</p>
                    <p>₹ {combinedReduxInsure?.quickQuote?.gstAmount}</p>
                  </div>
                  <div className="flex apart">
                    <p>NCB Discount</p>
                    <p>₹ {combinedReduxInsure?.quickQuote?.ncbDiscount}</p>
                  </div>
                  <Separator />

                  <div className="flex apart">
                    <p style={{ fontWeight: 'bold' }}>GRAND TOTAL</p>
                    <p>
                      ₹ {combinedReduxInsure?.quickQuote?.price}{' '}
                      <span>(Inclusive of all taxes)</span>
                    </p>
                  </div>
                  <Separator />
                </div>

                <ContinueBtn
                  onClick={
                    combinedReduxInsure?.inspection == 'required'
                      ? createBreakIn
                      : handlePayment
                  }
                >
                  {loading ? (
                    <Oval
                      height={25}
                      width={70}
                      color="#fff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#fff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : combinedReduxInsure?.inspection == 'required' ? (
                    'Self Inspection'
                  ) : (
                    'Pay Now'
                  )}
                </ContinueBtn>
              </div>
            </div>
          </CardContainer>
        </MainContainer>
        <Footer />
      </>
    );
}