import React from 'react';
import './paymentModeSection.css';
import bankOptions from '../../../utilities/bankOptions';

const PaymentModeSection = ({ formData, handleChange }) => {
  return (
    <div className="payment-mode-section">
      <h2>Payment Mode</h2>
      <div className="payment-mode-grid">
        <div className="form-group">
          <label htmlFor="paymentMode" className="paymentModeLabel">
            Payment Mode
          </label>
          <select
            id="paymentMode"
            name="paymentMode"
            className="paymentModeSelect"
            value={formData.paymentMode || ''}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
            <option value="CRS">CRS</option>
            <option value="CustFloat">CustFloat</option>
            <option value="Etransfer">Etransfer</option>
            <option value="Online">Online</option>
            <option value="UPI">UPI</option>
            <option value="None">None</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="amount" className="paymentModeLabel">
            Amount
          </label>
          <input
            type="number"
            id="amount"
            name="amount"
            className="paymentModeInput"
            value={formData.amount || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="noOfInstallments" className="paymentModeLabel">
            No. of Installments
          </label>
          <select
            id="noOfInstallments"
            name="noOfInstallments"
            className="paymentModeSelect"
            value={formData.noOfInstallments || ''}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>

        {formData.paymentMode !== 'Cash' && formData.paymentMode && (
          <>
            <div className="form-group required">
              <label htmlFor="bank" className="paymentModeLabel">
                Bank
              </label>
              <select
                id="bank"
                name="bank"
                className="paymentModeSelect"
                value={formData.bank}
                onChange={handleChange}
                required
              >
                <option value="">--Select--</option>
                {bankOptions.map((bank) => (
                  <option key={bank.value} value={bank.value}>
                    {bank.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group required">
              <label htmlFor="chequeNumber" className="paymentModeLabel">
                Chq/DD/Trn No.
              </label>
              <input
                type="text"
                id="chequeNumber"
                name="chequeNumber"
                className="paymentModeInput"
                value={formData.chequeNumber || ''}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="dated" className="paymentModeLabel">
                Dated
              </label>
              <input
                type="date"
                id="dated"
                name="dated"
                className="paymentModeInput"
                value={formData.dated || ''}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="bankBranch" className="paymentModeLabel">
                Bank Branch
              </label>
              <input
                type="text"
                id="bankBranch"
                name="bankBranch"
                className="paymentModeInput"
                value={formData.bankBranch || ''}
                onChange={handleChange}
              />
            </div>
          </>
        )}

        <div className="form-group">
          <label htmlFor="payModeRemark" className="paymentModeLabel">
            PayMode Remark
          </label>
          <input
            type="text"
            id="payModeRemark"
            name="payModeRemark"
            className="paymentModeInput"
            value={formData.payModeRemark || ''}
            onChange={handleChange}
          />
        </div>

        {/* Additional Fields */}

        <div className="form-group">
          <label htmlFor="micrCode" className="paymentModeLabel">
            MICR Code
          </label>
          <input
            type="text"
            id="micrCode"
            name="micrCode"
            className="paymentModeInput"
            value={formData.micrCode || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="optForEcs" className="paymentModeLabel">
            Opt for ECS
          </label>
          <select
            id="optForEcs"
            name="optForEcs"
            className="paymentModeSelect"
            value={formData.optForEcs || ''}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="premiumRemittingDate" className="paymentModeLabel">
            Premium Remitting Date
          </label>
          <input
            type="date"
            id="premiumRemittingDate"
            name="premiumRemittingDate"
            className="paymentModeInput"
            value={formData.premiumRemittingDate || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="chequeBy" className="paymentModeLabel">
            Cheque By
          </label>
          <select
            id="chequeBy"
            name="chequeBy"
            className="paymentModeSelect"
            value={formData.chequeBy || ''}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="Self">Self</option>
            <option value="Third Party">Third Party</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="premiumReceiptDate" className="paymentModeLabel">
            Premium Receipt Date
          </label>
          <input
            type="date"
            id="premiumReceiptDate"
            name="premiumReceiptDate"
            className="paymentModeInput"
            value={formData.premiumReceiptDate || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="premiumReceiptNo" className="paymentModeLabel">
            Premium Receipt No.
          </label>
          <input
            type="text"
            id="premiumReceiptNo"
            name="premiumReceiptNo"
            className="paymentModeInput"
            value={formData.premiumReceiptNo || ''}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentModeSection;
