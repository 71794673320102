import React from 'react';
import './campaignPolicyStatusSection.css';

const CampaignPolicyStatusSection = ({ formData, handleChange }) => {
  return (
    <div className="campaign-policy-status-section">
      <h2>Campaign : Policy Status</h2>
      <div className="campaign-policy-grid">
        <div className="form-group required">
          <label htmlFor="policyStatus" className="campaignPolicyLabel">
            Policy Status<span className="required-strick">*</span>
          </label>
          <select
            id="policyStatus"
            name="policyStatus"
            className="campaignPolicySelect"
            value={formData.policyStatus}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Cancelled">Cancelled</option>
            <option value="FreeLookupCancelled">Free Lookup Cancelled</option>
            <option value="HealthCheckup">Health Checkup</option>
            <option value="Inforce">Inforce</option>
            <option value="Lapsed">Lapsed</option>
            <option value="Matured">Matured</option>
            <option value="ReducedPaidup">Reduced Paidup</option>
            <option value="Surrendered">Surrendered</option>
            <option value="UnderProcess">Under Process</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="plvcCode" className="campaignPolicyLabel">
            PLVC Code
          </label>
          <input
            type="number"
            id="plvcCode"
            name="plvcCode"
            className="campaignPolicyInput"
            value={formData.plvcCode}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="plvcRemarks" className="campaignPolicyLabel">
            PLVC Remarks
          </label>
          <input
            type="text"
            id="plvcRemarks"
            name="plvcRemarks"
            className="campaignPolicyInput"
            value={formData.plvcRemarks}
            onChange={handleChange}
          />
        </div>
        <div className="form-group required">
          <label htmlFor="campaignName" className="campaignPolicyLabel">
            Campaign Name<span className="required-strick">*</span>
          </label>
          <select
            id="campaignName"
            name="campaignName"
            className="campaignPolicySelect"
            value={formData.campaignName}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="noCampaign">No Campaign</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default CampaignPolicyStatusSection;
