import React, { useState, useEffect } from 'react';
import './servicingDetails.css';
import { apiGETCall1 } from '../../../utilities/site-apis.jsx';
import axios from 'axios';

const ServicingDetailsSection = ({ formData, handleChange }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [posData, setPosData] = useState([]);

  // Function to fetch employee data based on Empcode and populate the respective field
  const fetchEmployeeData = async (empCode, empType, nameField) => {

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/allEmployeePolicy?Empcode=${empCode}`
      );

      // Ensure the response contains data
      if (response.data) {
        const employee = response.data.data.find((emp) => emp.EmpType === empType);

        if (employee) {
          handleChange({
            target: { name: nameField, value: employee.EmpName },
          });
          setErrorMessage('');
        } else {
          setErrorMessage(`No employee found with EmpType '${empType}'.`);
          handleChange({
            target: { name: nameField, value: '' },
          });
        }
      } else {
        setErrorMessage(`No employee found with Empcode '${empCode}'.`);
        handleChange({
          target: { name: nameField, value: '' },
        });
      }
    } catch (error) {
      console.error('Error fetching employee data:', error);
      setErrorMessage('Failed to fetch employee data. Please try again.');
    }
  };

const fetchPosData = async () => {
  try {
    const response = await apiGETCall1(`/pospInfo/find`, {});
    if (response?.data?.data?.data) {
      setPosData((prev) => {
        const newData = response.data.data.data;
        // Avoid setting state if data is already present
        if (JSON.stringify(prev) === JSON.stringify(newData)) {
          return prev;
        }
        return newData;
      });
      setErrorMessage('');
    } else {
      setErrorMessage(`No POS data found.`);
    }
  } catch (error) {
    console.error('Error fetching POS data:', error);
    setErrorMessage('Failed to fetch POS data. Please try again.');
  }
};

useEffect(() => {
  fetchPosData();
}, []);


  // Handlers for different input fields
  const handleCSCBlur = () =>
    fetchEmployeeData(formData.cscPeCode, 'CSC', 'cscPeName');
  const handleTCBlur = () => fetchEmployeeData(formData.tcCode, 'TC', 'tcName');
  const handleRMBlur = () =>
    fetchEmployeeData(formData.rmCe1Code, 'RM', 'rmCe1Name');
  // const handlePOSBlur = () =>
  //   fetchEmployeeData(formData.posMispRefCode, 'POS', 'posMispRefName');

  return (
    <div className="servicing-details-section">
      <h2>Servicing Details</h2>
      <div className="servicing-details-grid">
        {/* RM/CE1 Fields */}
        <div className="form-group required">
          <label htmlFor="rmCe1Code" className="servicingDetailsLabel">
            RM/CE1 Code<span className="required-strick">*</span>
          </label>
          <input
            type="text"
            id="rmCe1Code"
            name="rmCe1Code"
            className="servicingDetailsInput"
            value={formData.rmCe1Code}
            onChange={handleChange}
            onBlur={handleRMBlur} // Trigger fetch on blur
          />
          <button className="info-btn servicingDetailsButton">?</button>
        </div>
        <div className="form-group">
          <label htmlFor="rmCe1Name" className="servicingDetailsLabel">
            RM/CE1 Name
          </label>
          <input
            type="text"
            id="rmCe1Name"
            name="rmCe1Name"
            className="servicingDetailsInput"
            value={formData.rmCe1Name}
            onChange={handleChange}
            disabled
          />
        </div>

        {/* CSC/PE Fields */}
        <div className="form-group required">
          <label htmlFor="cscPeCode" className="servicingDetailsLabel">
            CSC/PE Code<span className="required-strick">*</span>
          </label>
          <input
            type="text"
            id="cscPeCode"
            name="cscPeCode"
            className="servicingDetailsInput"
            value={formData.cscPeCode}
            onChange={handleChange}
            onBlur={handleCSCBlur} // Trigger fetch on blur
          />
          <button className="info-btn servicingDetailsButton">?</button>
        </div>
        <div className="form-group">
          <label htmlFor="cscPeName" className="servicingDetailsLabel">
            CSC/PE Name
          </label>
          <input
            type="text"
            id="cscPeName"
            name="cscPeName"
            className="servicingDetailsInput"
            value={formData.cscPeName}
            onChange={handleChange}
            disabled
          />
        </div>

        {/* TC Fields */}
        <div className="form-group required">
          <label htmlFor="tcCode" className="servicingDetailsLabel">
            TC Code<span className="required-strick">*</span>
          </label>
          <input
            type="text"
            id="tcCode"
            name="tcCode"
            className="servicingDetailsInput"
            value={formData.tcCode}
            onChange={handleChange}
            onBlur={handleTCBlur} // Trigger fetch on blur
          />
          <button className="info-btn servicingDetailsButton">?</button>
        </div>
        <div className="form-group">
          <label htmlFor="tcName" className="servicingDetailsLabel">
            TC Name
          </label>
          <input
            type="text"
            id="tcName"
            name="tcName"
            className="servicingDetailsInput"
            value={formData.tcName}
            onChange={handleChange}
            disabled
          />
        </div>
        <div className="form-group required">
          <label htmlFor="posMispRef" className="servicingDetailsLabel">
            POS/MISP/Ref<span className="required-strick">*</span>
          </label>
          <select
            id="posMispRef"
            name="posMispRef"
            className="servicingDetailsSelect"
            value={formData.posMispRef}
            onChange={handleChange}
            required
          >
            <option value="Ref">Ref</option>
            <option value="POS">POS</option>
            <option value="MISP">MISP</option>
          </select>
        </div>
        {/* POS/MISP/Ref Fields */}
        <div className="form-group required">
          <label htmlFor="posMispRefCode" className="servicingDetailsLabel">
            POS/MISP/Ref Code<span className="required-strick">*</span>
          </label>
          <input
            type="text"
            id="posMispRefCode"
            name="posMispRefCode"
            className="servicingDetailsInput"
            value={formData.posMispRefCode}
            onChange={handleChange}
          />
          <button className="info-btn servicingDetailsButton">?</button>
        </div>
        <div className="form-group">
          <label htmlFor="posMispRefName" className="servicingDetailsLabel">
            POS/MISP/Ref Name
          </label>
          <input
            list="posList" // Links the input to the datalist by ID
            id="posMispRefName"
            name="posMispRefName"
            className="servicingDetailsInput"
            value={formData.posMispRefName}
            onChange={(e) => {
              const selectedName = e.target.value;

              // Call handleChange to update posMispRefName
              handleChange(e);

              // Find the matching POS data
              const matchingPos = posData.find(
                (pos) => pos.fullName === selectedName
              );
              if (matchingPos) {
                // Update posMispRefCode
                handleChange({
                  target: {
                    name: 'posMispRefCode',
                    value: matchingPos.posIncId,
                  },
                });

                // Update posId
                handleChange({
                  target: {
                    name: 'posId',
                    value: matchingPos._id,
                  },
                });
              }
            }}
            placeholder="Select or type POS/MISP/Ref Name"
          />
          <datalist id="posList">
            {posData.map((pos) => (
              <option key={pos.posIncId} value={pos.fullName} />
            ))}
          </datalist>
        </div>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default ServicingDetailsSection;
