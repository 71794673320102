import React, { useEffect, useState } from 'react';
import './policyDetailsSection.css';
import axios from 'axios';

const insurers = [
  {
    name: 'HDFC Life Insurance Company Limited',
    products: [
      { productName: 'HDFC LIFE CANCER CARE', department: 'TERM PLAN' },
      {
        productName: 'HDFC LIFE CLICK TO PROTECT',
        department: 'SAVINGS INSURANCE POLICY',
      },
      { productName: 'HDFC LIFE CLICK2 PROTECT LIFE', department: 'TERM PLAN' },
      {
        productName: 'HDFC LIFE SANCHAY PLUS',
        department: 'GUARANTEED INCOME PLAN',
      },
      {
        productName: 'HDFC LIFE SANCHAY PLUS LONG TERM INCOME PLAN',
        department: 'GUARANTEED INCOME PLAN',
      },
      {
        productName: 'HDFCLIFE SANCHAY PAR ADVANTAGE',
        department: 'SAVINGS PLAN',
      },
    ],
  },
  {
    name: 'TATA AIA Life Insurance Company Limited',
    products: [
      {
        productName: 'TATA AIA LIFE INSURANCE FORTUNE GUARANTEE PLUS',
        department: 'SAVINGS INSURANCE POLICY',
      },
      {
        productName: 'TATA AIA LIFE INSURANCE MAHA RAKSHA SUPREME SELECT',
        department: 'TERM PLAN',
      },
      {
        productName: 'TATA AIA LIFE INSURANCE SAMPOORNA RAKSHA PROMISE',
        department: 'TERM PLAN',
      },
    ],
  },
  {
    name: 'SBI Life Insurance Company Limited',
    products: [
      {
        productName: 'SBI LIFE - SMART PLATINA ASSURE',
        department: 'GUARANTEED INCOME PLAN',
      },
    ],
  },
  {
    name: 'Max Life Insurance Company Limited',
    products: [
      {
        productName: 'MAX LIFE SMART WEALTH ADVANTAGE GUARANTEE PLAN',
        department: 'GUARANTEED INCOME PLAN',
      },
    ],
  },
  {
    name: 'Life Insurance Corporation of India',
    products: [
      { productName: 'NEW ENDOWMENT PLAN', department: 'ENDOWMENT PLAN' },
    ],
  },
  {
    name: 'ICICI Prudential Life Insurance Company Limited',
    products: [{ productName: 'IPROTECT SMART', department: 'TERM PLAN' }],
  },
  {
    name: 'Go Digit Life Insurance Limited',
    products: [
      {
        productName: 'DIGIT LIFE GROUP TERM LIFE INSURANCE',
        department: 'TERM PLAN',
      },
      {
        productName: 'DIGIT LIFE GROUP TERM LIFE INSURANCE PLAN',
        department: 'GROUP TERM LIFE INSURANCE',
      },
      {
        productName: 'DIGIT LIFE GROUP TERM LIFE INSURANCE-1',
        department: 'TERM PLAN',
      },
    ],
  },
];

const PolicyDetailsSection = ({ formData, handleChange }) => {
  const [insurerData, setInsurerData] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [selectedInsurer, setSelectedInsurer] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const [fileName, setFileName] = useState('');

  const selectedInsurerData = insurers.find(
    (insurer) => insurer.name === formData.insurerName
  );
  const filteredProducts = selectedInsurerData
    ? selectedInsurerData.products
    : [];

  // filter department based on products.productName


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); // Save the file name to display
      console.log('Selected file:', file); // Add your custom logic here
    }
  };

  useEffect(() => {
    if (formData.startDate && formData.days) {
      const startPolicyDate = new Date(formData.startDate);
      if (formData.startDate && formData.days) {
        const minusOneDay = formData.days - 1;
        const expiryDate = new Date(startPolicyDate);
        expiryDate.setDate(
          startPolicyDate.getDate() + parseInt(minusOneDay, 10)
        );

        handleChange({
          target: {
            name: 'endDate',
            value: expiryDate.toISOString().split('T')[0],
          },
        });
      } else {
        handleChange({ target: { name: 'endDate', value: '' } });
      }
    }
  }, [formData.startDate, formData.days]);

  // Fetch insurer data from API
  useEffect(() => {
    const fetchInsurerDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lifeInsurerDetails`
        );
        setInsurerData(response?.data?.data); // Set the fetched data
      } catch (error) {
        console.error('Failed to fetch insurer details:', error);
      }
    };

    fetchInsurerDetails();
  }, []);

  // Handle Insurer selection
  const handleInsurerChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedInsurer(selectedValue);
    handleChange(e);

    // Filter cities based on selected insurer
    const cities = Array.from(
      new Set(
        insurerData
          .filter((insurer) => insurer.Insurer === selectedValue)
          .map((insurer) => insurer.City)
      )
    );
    setFilteredCities(cities);
    setFilteredBranches([]); // Reset branches
    setSelectedCity(''); // Reset selected city
    handleChange({ target: { name: 'insurerCity', value: '' } }); // Reset city field
    handleChange({ target: { name: 'insurerBranch', value: '' } }); // Reset branch field
  };

  // Handle City selection
  const handleCityChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCity(selectedValue);
    handleChange({ target: { name: 'insurerCity', value: selectedValue } });

    // Filter branches based on selected city
    const branches = insurerData
      .filter(
        (insurer) =>
          insurer.Insurer === selectedInsurer && insurer.City === selectedValue
      )
      .map((insurer) => ({
        id: insurer.BranchAutoCode,
        address: insurer.Address,
      }));
    setFilteredBranches(branches);
    handleChange({ target: { name: 'insurerBranch', value: '' } }); // Reset branch field
  };

  return (
    <div className="policy-details-section">
      <h2>Policy Details</h2>
      <div className="policy-details-grid">
        <div className="form-group required">
          <label htmlFor="insurerName" className="policyDetailsLabel">
            Insurer Name <span className="required-strick">*</span>
          </label>
          <select
            id="insurerName"
            name="insurerName"
            className="policyDetailsSelect"
            value={formData.insurerName}
            onChange={handleInsurerChange}
            required
          >
            <option value="">--Select--</option>
            {Array.from(
              new Set(insurerData.map((insurer) => insurer.Insurer))
            ).map((insurerName, index) => (
              <option key={index} value={insurerName}>
                {insurerName}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="insurerCity" className="policyDetailsLabel">
            Insurer City
          </label>
          <select
            id="insurerCity"
            name="insurerCity"
            className="policyDetailsSelect"
            value={selectedCity}
            onChange={handleCityChange}
            required
            disabled={!filteredCities.length} // Disable if no cities are available
          >
            <option value="">--Select--</option>
            {filteredCities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="insurerBranch" className="policyDetailsLabel">
            Insurer Branch <span className="required-strick">*</span>
          </label>
          <select
            id="insurerBranch"
            name="insurerBranch"
            className="policyDetailsSelect"
            value={formData.insurerBranch}
            onChange={handleChange}
            required
            disabled={!filteredBranches.length} // Disable if no branches are available
          >
            <option value="">--Select--</option>
            {filteredBranches.map((branch) => (
              <option key={branch.id} value={branch.address}>
                {branch.address}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="productName" className="policyDetailsLabel">
            Product Name <span className="required-strick">*</span>
          </label>
          <select
            id="productName"
            name="productName"
            className="policyDetailsSelect"
            value={formData.productName}
            onChange={handleChange}
            required
            disabled={!formData.insurerName}
          >
            <option value="">--Select--</option>
            {filteredProducts.map((product) => (
              <option key={product.productName} value={product.productName}>
                {product.productName}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="department" className="policyDetailsLabel">
            Department
          </label>
          <select
            id="department"
            name="department"
            className="policyDetailsSelect"
            value={formData.department}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            {
              // filter department based on products.productName
              filteredProducts.filter(
                (product) => product.productName === formData.productName 
              ).map((product) => (
                <option key={product.productName} value={product.department}>
                  {product.department}
                </option>
              ))
            }
            {/* {filteredProducts.map((product) => (
              <option key={product.productName} value={product.department}>
                {product.department}
              </option>
            ))} */}
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="proposalNo" className="policyDetailsLabel">
            Proposal No./App. No. <span className="required-strick">*</span>
          </label>
          <input
            type="text"
            id="proposalNo"
            name="proposalNo"
            className="policyDetailsInput"
            value={formData.proposalNo}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="submissionDate" className="policyDetailsLabel">
            Proposal Submission Date
          </label>
          <input
            type="date"
            id="submissionDate"
            name="submissionDate"
            className="policyDetailsInput"
            value={formData.submissionDate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="receivedDate" className="policyDetailsLabel">
            Proposal Received Date
          </label>
          <input
            type="date"
            id="receivedDate"
            name="receivedDate"
            className="policyDetailsInput"
            value={formData.receivedDate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="appBusDate" className="policyDetailsLabel">
            Appl(Bus) Date
          </label>
          <input
            type="date"
            id="appBusDate"
            name="appBusDate"
            className="policyDetailsInput"
            value={formData.appBusDate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="commenceDate" className="policyDetailsLabel">
            Commence (Start) Date <span className="required-strick">*</span>
          </label>
          <input
            type="date"
            id="commenceDate"
            name="commenceDate"
            className="policyDetailsInput"
            value={formData.commenceDate}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group required">
          <label htmlFor="paySchedule" className="policyDetailsLabel">
            Pay Schedule <span className="required-strick">*</span>
          </label>
          <select
            id="paySchedule"
            name="paySchedule"
            className="policyDetailsSelect"
            value={formData.paySchedule}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Yearly">Yearly</option>
            <option value="Half Yearly">Half Yearly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Single">Single</option>
            <option value="Monthly">Monthly</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="term" className="policyDetailsLabel">
            Term <span className="required-strick">*</span>
          </label>
          <input
            type="text"
            id="term"
            name="term"
            className="policyDetailsInput"
            value={formData.term}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group required">
          <label htmlFor="ppt" className="policyDetailsLabel">
            PPT <span className="required-strick">*</span>
          </label>
          <input
            type="text"
            id="ppt"
            name="ppt"
            className="policyDetailsInput"
            value={formData.ppt}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="maturityDate" className="policyDetailsLabel">
            Maturity Date
          </label>
          <input
            type="date"
            id="maturityDate"
            name="maturityDate"
            className="policyDetailsInput"
            value={formData.maturityDate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="lastPaymentDate" className="policyDetailsLabel">
            Last Payment Date
          </label>
          <input
            type="date"
            id="lastPaymentDate"
            name="lastPaymentDate"
            className="policyDetailsInput"
            value={formData.lastPaymentDate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="policyNo" className="policyDetailsLabel">
            Policy No.
          </label>
          <select
            id="policyNo"
            name="policyNo"
            className="policyDetailsSelect"
            value={formData.policyNo}
            onChange={handleChange}
          >
            <option value="">No</option>
            <option value="">Yes</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="moneyBackTime" className="policyDetailsLabel">
            Money Back Time
          </label>
          <select
            id="moneyBackTime"
            name="moneyBackTime"
            className="policyDetailsSelect"
            value={formData.moneyBackTime}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="moneyBackAmount" className="policyDetailsLabel">
            Money Back Amount
          </label>
          <input
            type="text"
            id="moneyBackAmount"
            name="moneyBackAmount"
            className="policyDetailsInput"
            value={formData.moneyBackAmount}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="refNo" className="policyDetailsLabel">
            Ref No.
          </label>
          <input
            type="text"
            id="refNo"
            name="refNo"
            className="policyDetailsInput"
            value={formData.refNo}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="attachment" className="form-label">
            {fileName || 'No file chosen'}
          </label>
          <input
            type="file"
            id="attachment"
            className="form-control"
            accept=".doc,.docx,.pdf,.txt,.xlsx"
            style={{ display: 'none' }} // Hide the file input
            onChange={handleFileChange}
          />
          <button
            className="add-btn"
            onClick={() => document.getElementById('attachment').click()}
          >
            Attach Document
          </button>
        </div>
      </div>
    </div>
  );
};

export default PolicyDetailsSection;
