import React, { useState } from 'react';
import './PolicyParticularDetails.css';

const PolicyParticularDetails = ({
  formData,
  handleAddData,
  addedData,
  handleDelete,
}) => {
  const [localFormData, setLocalFormData] = useState({
    sNo: 1,
    extensionType: 'Rider',
    extensionName: '',
    irdaLob: 'Health + Sickness',
    sumInsured: 0,
    premium: 0,
    taxable: 'Yes',
    riderRate: '',
    gstRate: '',
    gstAmount: 0,
    remarks: '',
    insuredAmount: 0,
    basicPremium: '',
    premiumAmount: '',
    totalRiderAmount: '',
    policyFees: '',
    avgGst: 0,

    // NEW FIELDS ADDED:
    gstSTaxAmount: 0, // GST/S.Tax Amount
    grossPremium: 0, // Gross Premium
    policyRemarks: '', // Policy Remarks
  });

  // Final Policy Data based om localFormData
  const [finalPolicyData, setFinalPolicyData] = useState({
    extensionType: 'Rider',
    extensionName: '',
    irdaLob: 'Health + Sickness',
    sumInsured: 0,
    premium: 0,
    taxable: 'Yes',
    riderRate: '',
    gstRate: '',
    gstAmount: 0,
    remarks: '',
    insuredAmount: 0,
    basicPremium: '',
    premiumAmount: '',
    totalRiderAmount: '',
    policyFees: '',
    avgGst: 0,

    // NEW FIELDS ADDED:
    gstSTaxAmount: 0, // GST/S.Tax Amount
    grossPremium: 0, // Gross Premium
    policyRemarks: '', // Policy Remarks
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Example of auto-calculate fields:
    if (name === 'gstRate' || name === 'premium') {
      const gstAmt =
        (parseFloat(localFormData.premium || 0) *
          parseFloat(localFormData.gstRate || 0)) /
        100;
      setLocalFormData((prev) => ({
        ...prev,
        gstAmount: gstAmt.toFixed(2),
        insuredAmount: parseFloat(prev.sumInsured),
      }));
    }
  };

  // Add local form data to the main array (parent state)
  const handleAddLocalData = () => {
    let updatedFormData = { ...localFormData };

    // Include the newly added entry in calculations
    const dataArray = [...addedData, updatedFormData];

    // 1️⃣ **Calculate Premium Amount (Sum of all premiums)**
    updatedFormData.premiumAmount = dataArray.reduce(
      (total, data) => total + Number.parseFloat(data.premium || 0),
      0
    );

    // 2️⃣ **Calculate Total Rider Amount based on Rider Rate**
    updatedFormData.totalRiderAmount = dataArray.reduce(
      (total, data) =>
        total +
        (Number.parseFloat(data.premium || 0) *
          Number.parseFloat(data.riderRate || 0)) /
          100,
      0
    );

    // 3️⃣ **Calculate GST Amount for each entry**
    updatedFormData.gstAmount = dataArray.reduce(
      (total, data) =>
        total +
        (Number.parseFloat(data.premium || 0) *
          Number.parseFloat(data.gstRate || 0)) /
          100,
      0
    );

    // 4️⃣ **Calculate Avg GST**
    updatedFormData.avgGst =
      dataArray.length > 0
        ? (updatedFormData.gstAmount / dataArray.length).toFixed(2)
        : 0;

    // 5️⃣ **Calculate Basic Premium**
    updatedFormData.basicPremium = (
      updatedFormData.premiumAmount - updatedFormData.totalRiderAmount
    ).toFixed(2);

    // 6️⃣ **Set GST/S.Tax Amount (Total GST Amount)**
    updatedFormData.gstSTaxAmount = updatedFormData.gstAmount;

    // 7️⃣ **Calculate Gross Premium (Premium Amount + GST Amount)**
    updatedFormData.grossPremium =
      updatedFormData.premiumAmount + updatedFormData.gstSTaxAmount;

    // Update `finalPolicyData` to reflect changes
    setFinalPolicyData((prevState) => ({
      ...prevState,
      premiumAmount: updatedFormData.premiumAmount,
      totalRiderAmount: updatedFormData.totalRiderAmount,
      basicPremium: updatedFormData.basicPremium,
      avgGst: updatedFormData.avgGst,
      gstSTaxAmount: updatedFormData.gstSTaxAmount,
      grossPremium: updatedFormData.grossPremium,
    }));

    // Update local state
    setLocalFormData((prevState) => ({
      ...prevState,
      ...updatedFormData,
    }));

    // Call `handleAddData` with the updated values
    handleAddData(updatedFormData, finalPolicyData);
  };

  // Reset the form fields, but keep the incremented S.No
  const handleReset = () => {
    setLocalFormData((prev) => ({
      sNo: prev.sNo, // keep the updated S.No
      extensionType: 'Rider',
      extensionName: '',
      irdaLob: 'Health + Sickness',
      sumInsured: 0,
      premium: 0,
      taxable: 'Yes',
      riderRate: '',
      gstRate: '',
      gstAmount: 0,
      insuredAmount: 0,
      basicPremium: '',
      premiumAmount: '',
      totalRiderAmount: '',
      policyFees: '',
      avgGst: 0,
      remarks: '',

      // Also reset new fields
      gstSTaxAmount: 0,
      grossPremium: 0,
      policyRemarks: '',
    }));
  };

  return (
    <div className="policy-particular-details">
      <h2>Policy Particular and Premium Details</h2>

      {/* PART 1: Main Premium/Extension Fields */}
      <div className="policy-particular-grid">
        {/* S.No */}
        <div className="form-group">
          <label htmlFor="sNo" className="policyParticularLabel">
            S.No
          </label>
          <input
            type="text"
            id="sNo"
            name="sNo"
            className="policyParticularInput"
            value={localFormData.sNo}
            onChange={handleChange}
            disabled
          />
        </div>

        {/* Extension Type */}
        <div className="form-group">
          <label htmlFor="extensionType" className="policyParticularLabel">
            Extension Type
          </label>
          <select
            id="extensionType"
            name="extensionType"
            className="policyParticularSelect"
            value={localFormData.extensionType}
            onChange={handleChange}
          >
            <option value="extension">Extension</option>
            <option value="Rider">Rider</option>
          </select>
        </div>

        {/* Extension Name */}
        <div className="form-group">
          <label htmlFor="extensionName" className="policyParticularLabel">
            Extension Name
          </label>
          <input
            type="text"
            id="extensionName"
            name="extensionName"
            className="policyParticularInput"
            value={localFormData.extensionName}
            onChange={handleChange}
          />
        </div>

        {/* IRDA LOB */}
        {localFormData.extensionType === 'Rider' && (
          <div className="form-group">
            <label htmlFor="irdaLob" className="policyParticularLabel">
              IRDA LOB
            </label>
            <select
              id="irdaLob"
              name="irdaLob"
              className="policyParticularSelect"
              value={localFormData.irdaLob}
              onChange={handleChange}
            >
              <option value="">--Select--</option>
              <option value="Health + Sickness">Health + Sickness</option>
              <option value="Accident + Disability">
                Accident + Disability
              </option>
              <option value="Term">Term</option>
              <option value="Other (PWB)">Other (PWB)</option>
            </select>
          </div>
        )}

        {/* Sum Insured */}
        <div className="form-group">
          <label htmlFor="sumInsured" className="policyParticularLabel">
            Sum Insured
          </label>
          <input
            type="number"
            id="sumInsured"
            name="sumInsured"
            className="policyParticularInput"
            value={localFormData.sumInsured}
            onChange={handleChange}
          />
        </div>

        {/* Premium */}
        <div className="form-group">
          <label htmlFor="premium" className="policyParticularLabel">
            Premium
          </label>
          <input
            type="number"
            id="premium"
            name="premium"
            className="policyParticularInput"
            value={localFormData.premium}
            onChange={handleChange}
          />
        </div>

        {/* Taxable */}
        <div className="form-group">
          <label htmlFor="taxable" className="policyParticularLabel">
            Taxable
          </label>
          <select
            id="taxable"
            name="taxable"
            className="policyParticularSelect"
            value={localFormData.taxable}
            onChange={handleChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Rider Rate */}
        <div className="form-group">
          <label htmlFor="riderRate" className="policyParticularLabel">
            Rider Rate
          </label>
          <input
            type="number"
            id="riderRate"
            name="riderRate"
            className="policyParticularInput"
            value={localFormData.riderRate}
            onChange={handleChange}
          />
        </div>

        {/* GST Rate */}
        {/* <div className="form-group">
          <label htmlFor="gstRate" className="policyParticularLabel">
            GST Rate
          </label>
          <input
            type="number"
            id="gstRate"
            name="gstRate"
            className="policyParticularInput"
            value={localFormData.gstRate}
            onChange={handleChange}
          />
        </div> */}

        <div className="form-group">
          <label htmlFor="gstRate" className="policyParticularLabel">
            GST Rate
          </label>
          <select
            id="gstRate"
            name="gstRate"
            className="policyParticularInput"
            value={localFormData.gstRate}
            onChange={handleChange}
          >
            <option value="0.00">0.00</option>
            <option value="4.00">4.00</option>
            <option value="4.50">4.50</option>
            <option value="5.00">5.00</option>
            <option value="5.50">5.50</option>
            <option value="10.30">10.30</option>
            <option value="12.00">12.00</option>
            <option value="12.24">12.24</option>
            <option value="12.36">12.36</option>
            <option value="14.00">14.00</option>
            <option value="14.50">14.50</option>
            <option value="15.00">15.00</option>
            <option value="18.00">18.00</option>
          </select>
        </div>

        {/* GST Amount */}
        <div className="form-group">
          <label htmlFor="gstAmount" className="policyParticularLabel">
            GST Amount
          </label>
          <input
            type="number"
            id="gstAmount"
            name="gstAmount"
            className="policyParticularInput"
            value={localFormData.gstAmount}
            onChange={handleChange}
            disabled
          />
        </div>

        {/* Remarks */}
        <div className="form-group">
          <label htmlFor="remarks" className="policyParticularLabel">
            Remarks
          </label>
          <input
            type="text"
            id="remarks"
            name="remarks"
            className="policyParticularInput"
            value={localFormData.remarks}
            onChange={handleChange}
          />
        </div>

        {/* ADD Button */}
        <div className="form-group">
          <button
            type="button"
            className="add-btn"
            onClick={handleAddLocalData}
          >
            ADD
          </button>
        </div>
      </div>

      <h3>Added Premium Details</h3>
      <table className="policy-particular-table">
        <thead>
          <tr>
            <th>Action</th>
            <th>S.No</th>
            <th>Extension Type</th>
            <th>Extension Name</th>
            <th>IRDA LOB</th>
            <th>Sum Insured</th>
            <th>Premium</th>
            <th>Taxable</th>
            <th>Rider Rate</th>
            <th>GST Rate</th>
            <th>GST Amount</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {addedData.map((data, index) => (
            <tr key={index}>
              <td>
                <button type="button" onClick={() => handleDelete(index)}>
                  Delete
                </button>
              </td>
              <td>{data.sNo}</td>
              <td>{data.extensionType}</td>
              <td>{data.extensionName}</td>
              <td>{data.irdaLob}</td>
              <td>{data.sumInsured}</td>
              <td>{data.premium}</td>
              <td>{data.taxable}</td>
              <td>{data.riderRate}</td>
              <td>{data.gstRate}</td>
              <td>{data.gstAmount}</td>
              <td>{data.remarks}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <hr style={{ marginTop: '20px', marginBottom: '20px' }} />

      {/* PART 2: Additional Fields */}
      <div className="policy-particular-grid">
        {/* Insured Amount */}
        <div className="form-group">
          <label htmlFor="insuredAmount" className="policyParticularLabel">
            Insured Amount
          </label>
          <input
            type="number"
            id="insuredAmount"
            name="insuredAmount"
            className="policyParticularInput"
            value={localFormData.insuredAmount}
            onChange={handleChange}
            disabled
          />
        </div>

        {/* Basic Premium */}
        <div className="form-group">
          <label htmlFor="basicPremium" className="policyParticularLabel">
            Basic Premium
          </label>
          <input
            type="number"
            id="basicPremium"
            name="basicPremium"
            className="policyParticularInput"
            value={localFormData.basicPremium}
            onChange={handleChange}
          />
        </div>

        {/* Premium Amount */}
        <div className="form-group">
          <label htmlFor="premiumAmount" className="policyParticularLabel">
            Premium Amount
          </label>
          <input
            type="number"
            id="premiumAmount"
            name="premiumAmount"
            className="policyParticularInput"
            value={localFormData.premiumAmount}
            onChange={handleChange}
          />
        </div>

        {/* Total Rider Amount */}
        <div className="form-group">
          <label htmlFor="totalRiderAmount" className="policyParticularLabel">
            Total Rider Amount
          </label>
          <input
            type="number"
            id="totalRiderAmount"
            name="totalRiderAmount"
            className="policyParticularInput"
            value={localFormData.totalRiderAmount}
            onChange={handleChange}
          />
        </div>

        {/* Policy Fees */}
        <div className="form-group">
          <label htmlFor="policyFees" className="policyParticularLabel">
            Policy Fees
          </label>
          <input
            type="number"
            id="policyFees"
            name="policyFees"
            className="policyParticularInput"
            value={localFormData.policyFees}
            onChange={handleChange}
          />
        </div>

        {/* Avg GST */}
        <div className="form-group">
          <label htmlFor="avgGst" className="policyParticularLabel">
            Avg GST
          </label>
          <input
            type="number"
            id="avgGst"
            name="avgGst"
            className="policyParticularInput"
            value={localFormData.avgGst}
            onChange={handleChange}
            disabled
          />
        </div>

        {/* NEW FIELD: GST/S.Tax Amount */}
        <div className="form-group">
          <label htmlFor="gstSTaxAmount" className="policyParticularLabel">
            GST/S.Tax Amount
          </label>
          <input
            type="number"
            id="gstSTaxAmount"
            name="gstSTaxAmount"
            className="policyParticularInput"
            value={localFormData.gstSTaxAmount}
            onChange={handleChange}
          />
        </div>

        {/* NEW FIELD: Gross Premium */}
        <div className="form-group">
          <label htmlFor="grossPremium" className="policyParticularLabel">
            Gross Premium
          </label>
          <input
            type="number"
            id="grossPremium"
            name="grossPremium"
            className="policyParticularInput"
            value={localFormData.grossPremium}
            onChange={handleChange}
          />
        </div>

        {/* NEW FIELD: Policy Remarks */}
        <div className="form-group">
          <label htmlFor="policyRemarks" className="policyParticularLabel">
            Policy Remarks
          </label>
          <input
            type="text"
            id="policyRemarks"
            name="policyRemarks"
            className="policyParticularInput"
            value={localFormData.policyRemarks}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PolicyParticularDetails;
