import { useNavigate } from "react-router-dom"
import { MainContainer, styles } from "../../../assets/common-styles/common-styles"
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent"
import { Layout } from "./styles";
import {useEffect , useState} from 'react'; 
import { useSelector, useDispatch } from "react-redux";
import { updateCombinedModel,modelVariant,RTOCity } from '../../../Redux/CarsRedux';

export const CarDetailsForm5 = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    const isFetching = useSelector((state) => state.carsRedux.isFetching);
    const cityList = useSelector((state) => state.carsRedux.rtoCitiesList);
    const rtoList = useSelector((state) => state.carsRedux.rtoListsList);
    const cityList1 = useSelector((state) => state.carsRedux);
    const [cityListState, setCityListState] = useState([]);
    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => {
        dispatch(RTOCity({uniqueCity:true}))
        if(!combinedRedux.hasOwnProperty('modelVariant')){
            setTimeout(() => {
                getLocalStorage()
            }, 1);
        }
        
    }, [])

    const getLocalStorage = async() => {
        var carInsuranceDetails =  await (localStorage.getItem('canInsuranceDetails'));
        if(carInsuranceDetails){
            carInsuranceDetails = JSON.parse(carInsuranceDetails);
            dispatch(updateCombinedModel(carInsuranceDetails));
        }
    };
    
    useEffect(() => {
        // if(cityList.hasOwnProperty('cityName') && cityList?.cityName?.length > 0) {
            // console.log("cityList are =", cityList);
            // let newList = [...cityList].sort()
            // console.log("newList ", newList);
            setCityListState(cityList)

        // }
    }, [cityList])

    const searchRto = async(e) => {
        let searchValue = e.target.value
        const newRto = await cityList?.filter((value) => {
            return value?.toLowerCase()?.includes(searchValue?.toLowerCase())
         })
         setCityListState(newRto)
    }
    const handleSelect = async(item,city) => {
        // await dispatch(updateCombinedModel({city:val}))
        // dispatch(RTOCity({cityName:val}))
        // setShowModal(true)
        var stateAndRTO = item.split(/(..)/g).filter(s => s);
        var stateCode = stateAndRTO[0];
        var RTO = stateAndRTO[1];
        dispatch(updateCombinedModel({stateCode:stateCode,rto:RTO,city:city})).then(()=>{
            navigate('/brand-new-model/car-form-preview',{ state: { fromForm: true} })
        })     
    }
    const handleModalSelect = (item) =>{
        var stateAndRTO = item.code.split(/(..)/g).filter(s => s);
        var stateCode = stateAndRTO[0];
        var RTO = stateAndRTO[1];
        dispatch(updateCombinedModel({stateCode:stateCode,rto:RTO, rtoCode:item.code})).then(()=>{
            navigate('/brand-new-model/car-form-preview',{ state: { fromForm: true} })
        })
    }
    return(
        <>
            <MainContainer>
                <Layout>
                    <CarDetailsComponent
                        title='Select City or RTO' step={7}
                        data={cityListState}
                        search='Search for city'
                        onSelect={handleSelect}
                        searchRto={searchRto}
                        cityList={1}
                        modalData={rtoList}
                        triggerModal={showModal}
                        modalLoading={isFetching}
                        handleModalClose={setShowModal}
                        handleModalSelect={handleModalSelect}
                        cityPage="active"
                    />
                </Layout>
            </MainContainer>
        </>
    )
}