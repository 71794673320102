import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import CustomerPopup from './CustomerPopup';
import './customerInfoSection.css';


const branchData = [
  {
    id: '0',
    branch:
      'NO.91/1, 1ST FLOOR, ABOVE ICICI BANK, INFANTRY ROAD, BANGALORE 560001, KARNATAKA, INDIA',
    type: 'Head Office',
  },
  {
    id: '2',
    branch: 'Plot No. 32, Income Tax Colony, Ranapratap Nagar, Nagpur',
    type: 'Nagpur',
  },
];

const getBranchDetailsByCode = (branchCode) => {
  const branch = branchData.find((b) => b.id === branchCode);
  return branch || null; // Return null if no matching branch is found
};



// Helper function to generate a 4-digit unique number
// function generateUniqueFourDigitNumber() {
//   const uuid = uuidv4();
//   const hash = parseInt(uuid.replace(/-/g, '').slice(0, 8), 16); 
//   return (hash % 9000) + 1000;
// }




const CustomerInfoSection = ({ formData, handleChange }) => {
    const [errorMessage, setErrorMessage] = useState('');
      const [isPopupOpen, setIsPopupOpen] = useState(false);
      const [customerList, setCustomerList] = useState([]);
      const [totalCustomers, setTotalCustomers] = useState(0);

 const fetchCustomersData = async (customerID) => {
   try {
     const response = await axios.get(
       `${process.env.REACT_APP_API_URL}/customers?customerId=${customerID}&policyEntry=${true}`
     );

     if (response.data && response.data.data && response?.data?.data?.data?.length > 0) {
       const customerData = response.data?.data?.data[0];
       setErrorMessage('');

       // Ensure correct field mapping
       handleChange({
         target: {
           name: 'customerName',
           value: customerData.fullName || '', // Use 'fullName' for customerName
         },
       });
       handleChange({
         target: {
           name: 'email',
           value: customerData.email || '', // Use 'email' field for email
         },
       });
       handleChange({
         target: {
           name: 'phone',
           value: customerData.phone || '', // Use 'phone' field for phone
         },
       });

       //  handleChange({
       //    target: {
       //     name: "enquiryCode",
       //     value: customerData.enquiryId || '',
       //     }
       //   });
     } else {
       setErrorMessage(`No customer found with Customer ID '${customerID}'.`);
     }
   } catch (error) {
     console.error('Error fetching customer data:', error);
     setErrorMessage('Failed to fetch customer data. Please try again.');
   }
 };


 const handleInfoButtonClick = async () => {
   try {
     const response = await axios.get(
       `${process.env.REACT_APP_API_URL}/customers?policyEntry=true`
     );

     if (response.data?.data?.data?.length > 0) {
       setCustomerList(response.data.data.data); // Set customer list
       setTotalCustomers(response.data.data.count); // Set total customers
       setIsPopupOpen(true); // Open the popup
     } else {
       alert('No customers available.');
     }
   } catch (error) {
     console.error('Error fetching customer data:', error);
     alert('Failed to fetch customer data. Please try again.');
   }
 };

 const handleCustomerSelect = (customer) => {
   handleChange({ target: { name: 'customerID', value: customer.customerId } });
   handleChange({ target: { name: 'customerName', value: customer.fullName } });
   handleChange({ target: { name: 'phone', value: customer.phone } });
   handleChange({ target: { name: 'email', value: customer.email || "" } });
   setIsPopupOpen(false); // Close the popup
 };


  const handleCustomerIDBlur = () => {
    if (formData.customerID.trim() !== '') {
      fetchCustomersData(formData.customerID);
    }
  };
//   (async () => {
//     try {
//       const data = await axios.get('http://localhost:3001/api/v1/motorPolicy');
//       console.log("🚀 ~ datawqqqqq:", data.data)
//       if (!data.ok) {
//         throw new Error(`HTTP error! status: ${data.status}`);
//       }

//       if (data.controlNo !== undefined) {
//         const newControlNo = data.controlNo + 1;
//         handleChange({ target: { name: 'controlNo', value: newControlNo } });
//       } else {
//         console.error('controlNo not found in the API response.');
//       }
//     } catch (error) {
//       console.error('Error fetching controlNo:', error);
//     }
//   })();
// }, []);

  return (
    <div className="customer-info-section">
      <h2>Customer Info Section</h2>
      <div className="customer-info-grid">
        <div className="form-group">
          <label htmlFor="controlNo" className="customerInfoSectionLabel">
            Control No.
          </label>
          <input
            type="text"
            className="customerInfoSectionInput"
            id="controlNo"
            name="controlNo"
            value={formData.controlNo}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="enquiryCode" className="customerInfoSectionLabel">
            Enquiry Id
          </label>
          <input
            type="text"
            id="enquiryCode"
            name="enquiryCode"
            className="customerInfoSectionInput"
            value={formData.enquiryCode}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="branchCode" className="customerInfoSectionLabel">
            Branch Code
          </label>
          <input
            type="text"
            id="branchCode"
            className="customerInfoSectionInput"
            name="branchCode"
            value={formData.branchCode}
            onChange={handleChange}
            onBlur={() => {
              const branchDetails = getBranchDetailsByCode(formData.branchCode);
              if (branchDetails) {
                handleChange({
                  target: { name: 'branchName', value: branchDetails.branch },
                });
                handleChange({
                  target: { name: 'branchType', value: branchDetails.type },
                });
              } else {
                alert('No branch found for the entered branch code.');
              }
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="branchName" className="customerInfoSectionLabel">
            Branch Name
          </label>
          <input
            type="text"
            id="branchName"
            name="branchName"
            className="customerInfoSectionInput"
            value={formData.branchName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="branchType" className="customerInfoSectionLabel">
            Branch Type
          </label>
          <input
            type="text"
            id="branchType"
            name="branchType"
            className="customerInfoSectionInput"
            
            value={formData.branchType}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="custCode" className="customerInfoSectionLabel">
            Cust Code<span className="required-strick">*</span>
          </label>
          <input
            type="text"
            id="customerID"
            className="customerInfoSectionInput"
            name="customerID"
            value={formData.customerID}
            onChange={handleChange}
            onBlur={handleCustomerIDBlur}
            required
          />

          <button
            className="info-btn"
            onClick={handleInfoButtonClick}
            type="button"
          >
            ?
          </button>
          {isPopupOpen && (
            <CustomerPopup
              totalCustomers={totalCustomers}
              customers={customerList}
              onSelect={handleCustomerSelect}
              onClose={() => setIsPopupOpen(false)}
            />
          )}
        </div>

        <div className="form-group">
          <label htmlFor="customerName" className="customerInfoSectionLabel">
            Customer Name
          </label>
          <input
            type="text"
            id="customerName"
            className="customerInfoSectionInput"
            name="customerName"
            value={formData.customerName}
            onChange={handleChange}
            disabled={formData.customerID.trim() !== ''}
          />
        </div>

        <div className="form-group">
          <label htmlFor="email" className="customerInfoSectionLabel">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="customerInfoSectionInput"
            value={formData.email}
            onChange={handleChange}
            disabled={formData.customerID.trim() !== ''}
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone" className="customerInfoSectionLabel">
            Phone
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            className="customerInfoSectionInput"
            value={formData.phone}
            onChange={handleChange}
            disabled={formData.customerID.trim() !== ''}
          />
        </div>

        <div className="form-group">
          <label htmlFor="insuredName" className="customerInfoSectionLabel">
            Insured/Proposer Name
          </label>
          <input
            type="text"
            id="insuredName"
            name="insuredName"
            className="customerInfoSectionInput"
            value={formData.insuredName}
            onChange={handleChange}
          />
          <button className="add-btn">+</button>
        </div>

        <div className="form-group">
          <label htmlFor="insuredDOB" className="customerInfoSectionLabel">
            Primary Insured DOB
          </label>
          <input
            type="date"
            id="insuredDOB"
            name="insuredDOB"
            className="customerInfoSectionInput"
            value={formData.dob}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="insuredAge" className="customerInfoSectionLabel">
            Primary Insured Age
          </label>
          <input
            type="text"
            id="insuredAge"
            name="insuredAge"
            className="customerInfoSectionInput"
            value={formData.insuredAge}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerInfoSection;
