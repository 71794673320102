import {useState} from 'react'
import policyImage from '../../../../assets/policy.svg'
import createPolicy from '../../../../assets/createPolicy.png'
import SearchImg from '../../../../assets/car-insurance/dashboard-icons/search.svg'
import { useNavigate } from "react-router-dom";
import excelImage from '../../../../assets/excel.svg'
import SelectComponent from './Select'
import { debounce } from 'lodash';
const TitleComponent = ({ title, searchIcon, noIcons, downloadIcon, handleOwnerSearch, exportCSV, selectedMenu, filterData, policyStatus, currentPage, setCurrentPage }) => {
  const [searchCriteria, setSearchCriteria] = useState('fullName');
  const navigate = useNavigate();
  const debouncedSearch = debounce((name) => {
    if (["pos", "rmpos", "userList"].includes(selectedMenu))
      filterData(name);
    else{
      filterData(currentPage, policyStatus, "", "", "", name, searchCriteria);
    }
  }, 1000);

  return (
    <div className="flex apart mb-1">
      <div className="flex align-center title-container">
        <h4 className="title">{title}</h4>
      </div>

      <div className="flex align-center search" style={{ gap: '10px' }}>
        {searchIcon ? (
          <div className="flex align-center search-box">
            <img
              src={SearchImg}
              className="search-img"
              alt="images for agent dashboard 2"
            />
            {[
              'policy',
              'healthPolicy',
              'lifePolicy',
              'bike',
              'marinePolicy',
              'othersPolicy',
            ].includes(selectedMenu) && (
              <SelectComponent
                options={[
                  { key: 'Name', value: 'fullName' },
                  { key: 'Email', value: 'email' },
                  { key: 'Phone', value: 'mobileNo' },
                  { key: 'Pan', value: 'panNumber' },
                  { key: 'Customer ID', value: 'customerID' },
                ]}
                value={searchCriteria}
                onChange={(e) => setSearchCriteria(e.target.value)}
              />
            )}
            <input
              className="search-input"
              placeholder="Search"
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </div>
        ) : null}
        {selectedMenu === 'policy' && (
          <div
            className="download icons"
            onClick={() => navigate('/policy-form')}
          >
            <img
              src={createPolicy}
              alt="images for agent dashboard 4"
              style={{ height: '25px', width: '25px' }}
            />
            <p style={{ fontWeight: 'bold' }} className="download-text">
              Add Policy
            </p>
          </div>
        )}

        {/* life policy */}
        {selectedMenu === 'policy' && (
          <div
            className="download icons"
            onClick={() => navigate('/life-policy-form')}
          >
            <img
              src={createPolicy}
              alt="images for agent dashboard 4"
              style={{ height: '25px', width: '25px' }}
            />
            <p style={{ fontWeight: 'bold' }} className="download-text">
              Add Life Policy
            </p>
          </div>
        )}
        {noIcons ? null : (
          <>
            <div className="download icons" onClick={() => navigate('/')}>
              <img
                src={policyImage}
                alt="images for agent dashboard 4"
                style={{ height: '25px', width: '25px' }}
              />
              <p style={{ fontWeight: 'bold' }} className="download-text">
                Buy Now
              </p>
            </div>
            {downloadIcon && (
              <div className="download icons" onClick={() => exportCSV()}>
                <img
                  src={excelImage}
                  alt="images for agent dashboard 4"
                  style={{ height: '25px', width: '25px' }}
                />
                <p style={{ fontWeight: 'bold' }} className="download-text">
                  Download
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default TitleComponent;