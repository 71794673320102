import React, { useState } from 'react';
import './nomineeDetailsSection.css';

const NomineeDetailsSection = ({
          formData,
          handleAddNominee,
          addedNominees,
          handleDeleteNominee
}) => {
  const [localNomineeData, setLocalNomineeData] = useState({
    sNo: 1,
    nomineeName: '',
    nomineeRelation: '',
    nomineeAge: '',
    nomineeShare: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalNomineeData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Add local nominee data to parent component's state
  const handleAddLocalNominee = () => {
    if (!localNomineeData.nomineeName || !localNomineeData.nomineeRelation) {
      alert('Please fill in all required fields!');
      return;
    }

    handleAddNominee({ ...localNomineeData }); // Pass nominee data to the parent handler
    setLocalNomineeData((prev) => ({
      sNo: prev.sNo + 1, // Increment S.No
      nomineeName: '',
      nomineeRelation: '',
      nomineeAge: '',
      nomineeShare: '',
    }));
  };

  return (
    <div className="nominee-details-section">
      <h3>Nominee Details</h3>

      <div className="nominee-details-grid">
        {/* S.No */}
        <div className="form-group">
          <label htmlFor="sNo">S.No:</label>
          <input
            type="text"
            id="sNo"
            name="sNo"
            value={localNomineeData.sNo}
            onChange={handleChange}
            disabled
          />
        </div>

        {/* Nominee Name */}
        <div className="form-group">
          <label htmlFor="nomineeName">Nominee Name:</label>
          <input
            type="text"
            id="nomineeName"
            name="nomineeName"
            value={localNomineeData.nomineeName}
            onChange={handleChange}
            placeholder="Enter nominee's name"
          />
        </div>

        {/* Nominee Relation */}
        <div className="form-group">
          <label htmlFor="nomineeRelation">Nominee Relation:</label>
          <select
            id="nomineeRelation"
            name="nomineeRelation"
            value={localNomineeData.nomineeRelation}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="Spouse">Spouse</option>
            <option value="Child">Child</option>
            <option value="Parent">Parent</option>
            <option value="Sibling">Sibling</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Nominee Age */}
        <div className="form-group">
          <label htmlFor="nomineeAge">Nominee Age:</label>
          <input
            type="number"
            id="nomineeAge"
            name="nomineeAge"
            value={localNomineeData.nomineeAge}
            onChange={handleChange}
            placeholder="Enter age"
          />
        </div>

        {/* Nominee Share */}
        <div className="form-group">
          <label htmlFor="nomineeShare">Nominee Share (%):</label>
          <input
            type="number"
            id="nomineeShare"
            name="nomineeShare"
            value={localNomineeData.nomineeShare}
            onChange={handleChange}
            placeholder="Enter share percentage"
          />
        </div>
      </div>

      <div className="form-group">
        <button
          type="button"
          className="add-btn"
          onClick={handleAddLocalNominee}
        >
          Add Nominee
        </button>
      </div>

      <h4>Added Nominees</h4>
      <table className="nominee-table">
        <thead>
          <tr>
            <th>Action</th>
            <th>S.No</th>
            <th>Name</th>
            <th>Relation</th>
            <th>Age</th>
            <th>Share (%)</th>
          </tr>
        </thead>
        <tbody>
          {addedNominees?.map((nominee, index) => (
            <tr key={index}>
              <td>
                <button
                  type="button"
                  onClick={() => handleDeleteNominee(index)}
                  className="delete-btn"
                >
                  Delete
                </button>
              </td>
              <td>{nominee.sNo}</td>
              <td>{nominee.nomineeName}</td>
              <td>{nominee.nomineeRelation}</td>
              <td>{nominee.nomineeAge}</td>
              <td>{nominee.nomineeShare}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NomineeDetailsSection;
