import React from 'react';
import './brokerageDetailsSection.css';

const BrokerageDetailsSection = ({ formData, handleChange }) => {
  return (
    <div className="brokerage-details-section">
      <h2>Brokerage Details</h2>
      <div className="brokerage-details-grid">
        <div className="form-group">
          <label htmlFor="brokerageType" className="brokerageDetailsLabel">
            Brok./Fee
          </label>
          <select
            id="brokerageType"
            name="brokerageType"
            className="brokerageDetailsSelect"
            value={formData.brokerageType}
            onChange={handleChange}
          >
            <option value="Brokerage">Brokerage</option>
            <option value="Fee">Fee</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="brokerageRemarks" className="brokerageDetailsLabel">
            Brokerage/Fee Remarks
          </label>
          <input
            type="text"
            id="brokerageRemarks"
            name="brokerageRemarks"
            className="brokerageDetailsInput"
            value={formData.brokerageRemarks}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="firstYear" className="brokerageDetailsLabel">
            First Year<span className="required-strick">*</span>
          </label>
          <input
            type="number"
            id="firstYear"
            name="firstYear"
            className="brokerageDetailsInput"
            value={formData.firstYear}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="brokerageAmount" className="brokerageDetailsLabel">
            Brok. Amount
          </label>
          <input
            type="number"
            id="brokerageAmount"
            name="brokerageAmount"
            className="brokerageDetailsInput"
            value={formData.brokerageAmount}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group required">
          <label htmlFor="secondYear" className="brokerageDetailsLabel">
            Second Year<span className="required-strick">*</span>
          </label>
          <input
            type="number"
            id="secondYear"
            name="secondYear"
            className="brokerageDetailsInput"
            value={formData.secondYear}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="finalYear" className="brokerageDetailsLabel">
            Final Year<span className="required-strick">*</span>
          </label>
          <input
            type="number"
            id="finalYear"
            name="finalYear"
            className="brokerageDetailsInput"
            value={formData.finalYear}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="gstSTax" className="brokerageDetailsLabel">
          GST/S. Tax %
        </label>
        <input
          type="number"
          id="gstSTax"
          name="gstSTax"
          className="brokerageDetailsInput"
          value={formData.gstSTax}
          onChange={handleChange}
        />
      </div>

      {/* <button
        type="button"
        className="calculate-rate-btn brokerageDetailsButton"
      >
        Get POS/MISP Rate
      </button> */}
    </div>
  );
};

export default BrokerageDetailsSection;
