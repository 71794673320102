import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetCall, apiGetCallVariants, apiPostCall, apiPostCall1 } from "../utilities/site-apis";
import { axiosPOSTCall, axiosGETCall, } from "../utilities/utils/apiPost";

const initialState = {
  isFetching: false,
  vehicleList: [],
  success: false,
  error: false,
  modalList: [],
  combinedRedux: {},
  fuelType: [],
  changedVariant: [],
  cubicCap: [],
  errorMessage: '',
  variantsList: [],
  rtoCitiesList: [],
  rtoListsList: [],
  prevInsurerList: [],
  motorGodigitQuote: {},
  motorQuote: [],
  addOnsList: [],
  saveProposal: {},
  paymentDetails: {},
  selectedModal: {},
  selectIDV: {},
  carInfoSuccess: false,
  pdfData: {},

};

export const vehicleInsuranceList = createAsyncThunk(
  "carInsurance/vehicleInsuranceList",
  async (params, { rejectWithValue }) => {
    const response = await apiGetCall(`/vehicleInsurance/make/list`, params);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);

export const saveProposalWrapUp = createAsyncThunk(
  "carInsurance/saveProposalWrapUp",
  async (params, { rejectWithValue }) => {
    // console.log("params are ----> ", params)
    return params;
  }
)

export const SelectCarModalList = createAsyncThunk(
  "carInsurance/SelectModel",
  async (params, { rejectWithValue }) => {
    const response = await apiGetCall(
      `vehicleInsurance/makeModel/list`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);
export const updateCombinedModel = createAsyncThunk(
  "carInsurance/updateCombinedModel",
  async (params, { rejectWithValue }) => {
    return params
  }
);


export const modelVariant = createAsyncThunk(
  "carInsurance/modelVariant1",
  async (params, { rejectWithValue }) => {
    const response = await apiGetCall(
      `/vehicleInsurance/modelVariant/list`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);

export const selectedModal = createAsyncThunk(
  "carInsurance/modelVariant1",
  async (params, { rejectWithValue }) => {
    return params;
  }
);

export const selectIDV = createAsyncThunk(
  "carInsurance/selectIdv",
  async (params, { rejectWithValue }) => {
    console.log("params are -> ", params);
    return params;
  }
);

export const motorEnquiry = createAsyncThunk(
  "motorEnquiry/carInsurance/modelVariant",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/vehicleInsurance/motor/quote/motor-godigit`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);

export const motorEnquiryPayment = createAsyncThunk(
  "enquiry/payment/",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall1(`/motor/enquiry/payment`, params);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);

export const motorEnquiryPaymentVerification = createAsyncThunk(
  "enquiry/payment/verify",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall1(
      `/motor/enquiry/payment/verify`,
      params
    )
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    console.log("response.data is -----> ", response.data);
    if (response?.data?.data?.isRedirect) {
      window.location.href = response.data.data.link
      return;
    }
    return response.data;
  }
);

export const motorEnquiryAPI = createAsyncThunk(
  "carInsurance/modelVariant",
  async (params, { rejectWithValue }) => {
    // console.log("🚀 ~ file: CarsRedux.jsx ~ line 106 ~ params", params.type);
    var response;
    if (params.hasOwnProperty("type") && params.type === "get") {
      response = await apiGetCall(`/motor/enquiry/` + params.enquiryId, params);
      console.log('response', response.data);
    } else {
      response = await apiPostCall1(`/motor/enquiry`, params);
      console.log('response1', response)
    }
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    console.log("response.data ", response.data);
    return response.data;
  }
);

export const RTOCity = createAsyncThunk(
  "carInsurance/RTOCity",
  async (params, { rejectWithValue }) => {
    const response = await apiGetCall(`/vehicleInsurance/rtoCity/list`, params);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);

export const PrevInsurerData = createAsyncThunk(
  "carInsurance/PrevInsurerData",
  async (params, { rejectWithValue }) => {
    const response = await apiGetCall(`/previousInsurerMaster/find`, params);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    // console.log("response.data -->", response.data);
    return response.data;
  }
);

export const getCarInfo = createAsyncThunk(
  "insurance/getCarInfo",
  async (params, { rejectWithValue }) => {
    const response = await apiGetCall(`/vehicle/details`, params);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);

export const getAddons = createAsyncThunk(
  "addOn/find",
  async (params, { rejectWithValue }) => {
    const response = await apiGetCall(`/addOn/find`, params);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);

export const resetRedux = createAsyncThunk(
  "insurance/resetRedux",
  async (params, { rejectWithValue }) => {
    return 0;
  }
);
export const resetMotorQuoteRedux = createAsyncThunk(
  "insurance/resetMotorQuoteRedux",
  async (params, { rejectWithValue }) => {
    return 0;
  }
);

export const KotakMotorQuote = createAsyncThunk(
  "KotakMotor/quote",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/vehicleInsurance/motor/quote/motor-kotak`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);

export const HDFCMotorQuote = createAsyncThunk(
  "KotakMotor/quote",
  async (params, { rejectWithValue }) => {
    // console.log(
    //   "🚀 ~ file: CarsRedux.jsx ~ line 189 ~ params",
    //   JSON.stringify(params)
    // );
    const response = await apiPostCall(
      `/vehicleInsurance/motor/quote/motor-hdfc`,
      params
    );
    // console.log("🚀 ~ file: CarsRedux.jsx ~ line 190 ~ response", response);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);

export const HDFCMotorUpdateQuote = createAsyncThunk(
  "hdfcMotor/quote",
  async (params, { rejectWithValue }) => {
    // console.log(
    //   "🚀 ~ file: CarsRedux.jsx ~ line 189 ~ params",
    //   JSON.stringify(params)
    // );
    const response = await apiPostCall(
      `/vehicleInsurance/motor/quote/motor-hdfc`,
      params
    );
    // console.log("🚀 ~ file: CarsRedux.jsx ~ line 190 ~ response", response);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);

export const RELIANCEMotorUpdateQuote = createAsyncThunk(
  "relianceMotor/quote",
  async (params, { rejectWithValue }) => {
    // console.log(
    //   "🚀 ~ file: CarsRedux.jsx ~ line 189 ~ params",
    //   JSON.stringify(params)
    // );
    const response = await apiPostCall(
      `/vehicleInsurance/motor/quote/motor-reliance`,
      params
    );
    // console.log("🚀 ~ file: CarsRedux.jsx ~ line 190 ~ response", response);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);

export const ICICIMotorUpdateQuote = createAsyncThunk(
  "icici/updatequote",
  async (params, { rejectWithValue }) => {
    // console.log(
    //   "🚀 ~ file: CarsRedux.jsx ~ line 189 ~ params",
    //   JSON.stringify(params)
    // );
    const response = await apiPostCall(
      `/vehicleInsurance/motor/quote/motor-icici`,
      params
    );
    // console.log("🚀 ~ file: CarsRedux.jsx ~ line 190 ~ response", response);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);

export const KOTAKMotorUpdateQuote = createAsyncThunk(
  "kotak/updatequote",
  async (params, { rejectWithValue }) => {
    // console.log(
    //   "🚀 ~ file: CarsRedux.jsx ~ line 189 ~ params",
    //   JSON.stringify(params)
    // );
    const response = await apiPostCall(
      `/vehicleInsurance/motor/quote/motor-kotak`,
      params
    );
    // console.log("🚀 ~ file: CarsRedux.jsx ~ line 190 ~ response", response);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);
export const GODIGITMotorUpdateQuote = createAsyncThunk(
  "kotak/updatequote",
  async (params, { rejectWithValue }) => {
    // console.log(
    //   "🚀 ~ file: CarsRedux.jsx ~ line 189 ~ params",
    //   JSON.stringify(params)
    // );
    const response = await apiPostCall(
      `/vehicleInsurance/motor/quote/motor-godigit`,
      params
    );
    // console.log("🚀 ~ file: CarsRedux.jsx ~ line 190 ~ response", response);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);
export const FilterVaraintName = createAsyncThunk(
  "FilterVariant/quote",
  async (params, { rejectWithValue }) => {
    console.log("🚀 ~ file: CarsRedux.jsx:278 ~ params:", params)
    // console.log(
    //   "🚀 ~ file: CarsRedux.jsx ~ line 189 ~ params",
    //   JSON.stringify(params)
    // );
    const response = await apiGetCallVariants(
      `/vehicleInsurance/filterVariants`, params.slug,
      params.query
    );
    // console.log("🚀 ~ file: CarsRedux.jsx ~ line 190 ~ response", response);
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);

export const GODIGITMotorQuote = createAsyncThunk(
  "motor/quote",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/vehicleInsurance/motor/quote/motor-godigit`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
)

export const ICICIMotorQuote = createAsyncThunk(
  'icici/quote',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/vehicleInsurance/motor/quote/motor-icici`, params);
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if (params.newData == 'new') {
      var obj2 = {
        ...response.data, ...{
          newData: params.newData
        }
      };
      return obj2
    }
    else {
      return response.data
    }
  }
)
//  Reliance motor quote thunk
export const RELIANCEMotorQuote = createAsyncThunk(
  'reliance/quote',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/vehicleInsurance/motor/quote/motor-reliance`, params);
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if (params.newData == 'new') {
      var obj2 = {
        ...response.data, ...{
          newData: params.newData
        }
      };
      return obj2
    }
    else {
      return response.data
    }
  }
)

//  FutureGeneral motor quote thunk
export const FutureGeneralMotorQuote = createAsyncThunk(
  'future/quote',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/vehicleInsurance/motor/quote/motor-future`, params);
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if (params.newData == 'new') {
      var obj2 = {
        ...response.data, ...{
          newData: params.newData
        }
      };
      return obj2
    }
    else {
      return response.data
    }
  }
)

export const FutureGeneralMotorUpdateQuote = createAsyncThunk(
  "future/updateQuote",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/vehicleInsurance/motor/quote/motor-future`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
      return response.data;
  }
);

export const kotakMotorSaveProposal = createAsyncThunk(
  'create/saveProposal',
  async (params, { rejectWithValue }) => {
    const response = await axiosPOSTCall(`/vehicleInsurance/motor/saveProposal/motor-kotak`, params);
    if (response.data.status === 'error') {
      alert("please verify details", response.data)
      return rejectWithValue(response.data)
    }
    else {
      return response.data
    }

  }
)
export const updateSaveProposalDigit = createAsyncThunk(
  "create/saveProposal",
  async (params, { rejectWithValue }) => {
    console.log("params -----> ", params);
    return params;
  }
);

export const updateSaveProposalKotak = createAsyncThunk(
  "create/saveProposal",
  async (params, { rejectWithValue }) => {
    console.log("params -----> ", params);
    return params;
  }
);
export const updateSaveProposalHdfc = createAsyncThunk(
  "create/saveProposal",
  async (params, { rejectWithValue }) => {
    console.log("params -----> ", params);
    return params;
  }
);
export const updateSaveProposalIcici = createAsyncThunk(
  "create/saveProposal",
  async (params, { rejectWithValue }) => {
    console.log("params -----> ", params);
    return params;
  }
);
export const updateSaveProposalReliance = createAsyncThunk(
  "creates/saveProposal",
  async (params, { rejectWithValue }) => {
    console.log("params -----> ", params);
    return params;
  }
);
export const updateSaveProposal = createAsyncThunk(
  "creates/saveProposal",
  async (params, { rejectWithValue }) => {
    return params;
  }
);
export const ICICIMotorSaveProposal = createAsyncThunk(
  'create/saveProposal',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/vehicleInsurance/motor/saveProposal/motor-icici`, params);
    if (response.data.status === 'error') {
      alert("please verify details", response.data)
      return rejectWithValue(response.data)
    }
    else {
      return response.data
    }

  }
)

export const counterSlice = createSlice({
  name: "carInsurance",
  initialState,
  reducers: {
    setVehicleInsuranceList: (state, action) => {
      state.vehicleList = action.payload;
    },
  },
  extraReducers: {
    [vehicleInsuranceList.pending]: (state, action) => {
      state.isFetching = true;
    },
    [vehicleInsuranceList.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.errorMessage = action.payload.message;
    },
    [vehicleInsuranceList.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.vehicleList = action.payload.data;
      // state.success = true
      state.error = false;
    },
    [SelectCarModalList.pending]: (state, action) => {
      state.isFetching = true;
    },
    [SelectCarModalList.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [SelectCarModalList.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.modalList = action.payload.data;
      state.success = true;
      state.error = false;
    },
    [GODIGITMotorQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [GODIGITMotorQuote.rejected]: (state, action) => {
      // state.isFetching = false
      state.error = true;
    },
    [GODIGITMotorQuote.fulfilled]: (state, action) => {
      state.motorQuote = [...state.motorQuote, { ...action.payload.data }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },
    [GODIGITMotorUpdateQuote.fulfilled]: (state, action) => {

      const existingIndex = state.motorQuote.findIndex(obj => obj.slug == "motor-godigit");

      if (existingIndex !== -1) {

        // If the object already exists, update it
        state.motorQuote[existingIndex] = action.payload.data;
      }

    },
    [updateSaveProposalKotak.fulfilled]: (state, action) => {
      state.combinedRedux = { ...state.combinedRedux, ...action.payload.data };
      state.error = false;
    },
    [updateSaveProposalHdfc.fulfilled]: (state, action) => {
      state.combinedRedux = { ...state.combinedRedux, ...action.payload.data };
      state.error = false;
    },
    [updateSaveProposal.fulfilled]: (state, action) => {
      state.combinedRedux = { ...state.combinedRedux, ...action.payload.data };
      state.error = false;
    },
    [updateSaveProposalIcici.fulfilled]: (state, action) => {
      state.combinedRedux = { ...state.combinedRedux, ...action.payload.data };
      state.error = false;
    },
    [updateSaveProposalReliance.fulfilled]: (state, action) => {
      state.combinedRedux = { ...state.combinedRedux, ...action.payload.data };
      state.error = false;
    },
    [updateSaveProposalDigit.fulfilled]: (state, action) => {
      state.combinedRedux = { ...state.combinedRedux, ...action.payload.data };
      state.error = false;
    },
    [ICICIMotorSaveProposal.pending]: (state, action) => {
      state.isFetching = true
    },
    [ICICIMotorSaveProposal.rejected]: (state, action) => {
      // state.isFetching = false
      state.error = true
    },
    [ICICIMotorSaveProposal.fulfilled]: (state, action) => {
      state.saveProposal = [...state.saveProposal, { ...action.payload.data }]
      state.success = true
      state.error = false
      state.isFetching = false
    },

    [KotakMotorQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [KotakMotorQuote.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [KotakMotorQuote.fulfilled]: (state, action) => {
      state.motorQuote = [...state.motorQuote, { ...action.payload.data }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },
    [KOTAKMotorUpdateQuote.fulfilled]: (state, action) => {

      const existingIndex = state.motorQuote.findIndex(obj => obj.slug == "motor-kotak");

      if (existingIndex !== -1) {

        // If the object already exists, update it
        state.motorQuote[existingIndex] = action.payload.data;
      }

    },

    [HDFCMotorQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [HDFCMotorQuote.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [HDFCMotorQuote.fulfilled]: (state, action) => {
      state.motorQuote = [...state.motorQuote, { ...action.payload.data }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    [HDFCMotorUpdateQuote.fulfilled]: (state, action) => {

      const existingIndex = state.motorQuote.findIndex(obj => obj.slug == "motor-hdfc");

      if (existingIndex !== -1) {

        // If the object already exists, update it
        state.motorQuote[existingIndex] = action.payload.data;
      }

    },
    [FilterVaraintName.pending]: (state, action) => {
      state.isFetching = true;
    },
    [FilterVaraintName.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [FilterVaraintName.fulfilled]: (state, action) => {
      state.changedVariant = [{ ...action.payload.data }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },
    [ICICIMotorQuote.pending]: (state, action) => {
      state.isFetching = true
    },
    [ICICIMotorQuote.rejected]: (state, action) => {
      // state.isFetching = false
      state.error = true
    },
    [ICICIMotorQuote.fulfilled]: (state, action) => {
      state.motorQuote = [...state.motorQuote, { ...action.payload.data }]
      state.success = true
      state.error = false
      state.isFetching = false
    },
    // reliance motor action reducer
    [RELIANCEMotorQuote.pending]: (state, action) => {
      state.isFetching = true
    },
    [RELIANCEMotorQuote.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [RELIANCEMotorUpdateQuote.fulfilled]: (state, action) => {
      const existingIndex = state.motorQuote.findIndex(obj => obj.slug == "motor-reliance");

      if (existingIndex !== -1) {

        // If the object already exists, update it
        state.motorQuote[existingIndex] = action.payload.data;
      }
    },
    [ICICIMotorUpdateQuote.fulfilled]: (state, action) => {
      const existingIndex = state.motorQuote.findIndex(obj => obj.slug == "motor-icici");

      if (existingIndex !== -1) {

        // If the object already exists, update it
        state.motorQuote[existingIndex] = action.payload.data;
      }
    },
    // FutureGeneralMotorQuote
    [FutureGeneralMotorQuote.pending]: (state, action) => {
      state.isFetching = true
    },
    [FutureGeneralMotorQuote.rejected]: (state, action) => {
      state.error = true
    },
    [FutureGeneralMotorQuote.fulfilled]: (state, action) => {
      state.motorQuote = [...state.motorQuote, { ...action.payload.data }]
      state.success = true
      state.error = false
      state.isFetching = false
    },
    [FutureGeneralMotorUpdateQuote.fulfilled]: (state, action) => {
      const existingIndex = state.motorQuote.findIndex(obj => obj.slug == "motor-future");
      if (existingIndex !== -1) {
        state.motorQuote[existingIndex] = action.payload.data;
      }
    },
    [motorEnquiryPayment.pending]: (state, action) => {
      state.isFetching = true;
    },
    [motorEnquiryPayment.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [motorEnquiryPayment.fulfilled]: (state, action) => {
      state.isFetching = false
      state.success = true
      state.paymentDetails = action.payload.data
      state.error = false
    },


    [selectedModal.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [selectedModal.fulfilled]: (state, action) => {
      state.selectedModal = action.payload;
    },
    [selectIDV.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [selectIDV.fulfilled]: (state, action) => {
      state.selectIDV = action.payload;
    },
    [modelVariant.pending]: (state, action) => {
      state.isFetching = true;
    },
    [modelVariant.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [modelVariant.fulfilled]: (state, action) => {
      if (action.payload.data[0]?.hasOwnProperty("fuelType")) {
        state.fuelType = action.payload.data[0].fuelType;
      } else if (action.payload.data[0]?.hasOwnProperty("cubicCap")) {
        state.cubicCap = action.payload.data[0].cubicCap;
      } else if (action.payload.data?.hasOwnProperty("variantList")) {
        state.variantsList = action.payload.data.variantList;
      }
      state.isFetching = false;
      state.success = true;
    },
    [RTOCity.pending]: (state, action) => {
      state.isFetching = true;
    },
    [RTOCity.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [RTOCity.fulfilled]: (state, action) => {
      if (
        action.payload.data[0]?.hasOwnProperty("cityName") &&
        action.payload.data[0]?._id == null
      ) {
        state.rtoCitiesList = action.payload.data[0].cityName;
      } else if (
        action.payload.data[0]?.hasOwnProperty("cityName") &&
        action.payload.data[0]?._id != null
      ) {
        state.rtoListsList = action.payload.data;
      }
      state.isFetching = false;
      state.success = true;
      state.error = false;
    },

    [PrevInsurerData.pending]: (state, action) => {
      state.isFetching = true;
    },
    [PrevInsurerData.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    [PrevInsurerData.fulfilled]: (state, action) => {
      state.prevInsurerList = action.payload.data;
      state.isFetching = false;
      state.success = true;
      state.error = false;
    },

    [updateCombinedModel.fulfilled]: (state, action) => {
      var payload = action.payload;
      var canInsuranceDetails = localStorage.getItem("canInsuranceDetails");
      if (canInsuranceDetails) {
        canInsuranceDetails = JSON.parse(canInsuranceDetails);
        canInsuranceDetails = Object.assign(
          canInsuranceDetails,
          action.payload
        );
        localStorage.setItem(
          "canInsuranceDetails",
          JSON.stringify(canInsuranceDetails)
        );
      } else {
        localStorage.setItem(
          "canInsuranceDetails",
          JSON.stringify(action.payload)
        );
      }
      state.combinedRedux = { ...state.combinedRedux, ...payload };
      state.error = false;
    },

    [getCarInfo.pending]: (state, action) => {
      state.isFetching = true;
    },
    [getCarInfo.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
      if (action?.payload?.message) {
        state.errorMessage = action.payload.message;
      } else {
        console.log("error in update model",action?.payload?.message)
        // alert("Network error, Please try again later.");
      }
    },
    [getCarInfo.fulfilled]: (state, action) => {
      // console.log(
      //   "🚀 ~ file: CarsRedux.jsx ~ line 396 ~ action",
      //   action.payload
      // );
      state.combinedRedux = action.payload.data;
      localStorage.setItem(
        "canInsuranceDetails",
        JSON.stringify(action.payload.data)
      );
      state.carInfoSuccess = true;
      state.error = false;
    },

    [getAddons.rejected]: (state, action) => {
      state.error = true;
    },
    [getAddons.fulfilled]: (state, action) => {
      state.addOnsList = action.payload.data;
      state.success = true;
      state.error = false;
    },

    [saveProposalWrapUp.fulfilled]: (state, action) => {
      state.pdfData = { ...state.posData, ...action.payload };
      state.success = true;
      state.error = false;
    },

    [motorEnquiryAPI.rejected]: (state, action) => {
      state.error = true;
      state.errorMessage = action.payload.message;
    },
    [motorEnquiryAPI.fulfilled]: (state, action) => {
      var payload = action.payload.data;
      var canInsuranceDetails = localStorage.getItem("canInsuranceDetails");
      // console.log(
      //   "🚀 ~ file: CarsRedux.jsx ~ line 385 ~ canInsuranceDetails",
      //   canInsuranceDetails
      // );
      if (canInsuranceDetails) {
        canInsuranceDetails = JSON.parse(canInsuranceDetails);
        canInsuranceDetails = Object.assign(
          canInsuranceDetails,
          action.payload.data
        );
        localStorage.setItem(
          "canInsuranceDetails",
          JSON.stringify(canInsuranceDetails)
        );
      } else {
        localStorage.setItem(
          "canInsuranceDetails",
          JSON.stringify(action.payload.data)
        );
      }
      console.log("action.payload.data", payload);
      state.combinedRedux = action.payload.data;
      state.success = true;
      state.error = false;
    },
    // [getCarInfo.pending]: (state, action) => {
    //   state.isFetching = true
    // },
    // [getCarInfo.rejected]: (state, action) => {
    //   state.isFetching = false
    //   state.error = true
    // },
    // [getCarInfo.fulfilled]: (state, action) => {
    //   state.motorQuote = action.payload
    //   state.success = true
    //   state.isFetching = false
    // },
    [resetRedux.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.combinedRedux = {};
      state.success = false;
    },
    [resetMotorQuoteRedux.fulfilled]: (state, action) => {
      state.motorQuote = [];
    },
  },
});

export const { setVehicleInsuranceList } = counterSlice.actions;
export default counterSlice.reducer;
